<template>
    <div>
        <div class="flex h-screen justify-center">
            <form class="w-full max-w-lg mx-2 mt-6" @submit.prevent>
                <h1 class="text-3xl pl-2 font-bold text-left mb-6">Add order</h1>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Order Number
                        </label>
                        <input v-model="orderId" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Enter id">
                    
                    </div>
                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Customer name
                        </label>
                        <input v-model="customerName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Customer name">
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Customer Email
                        </label>
                        <input v-model="customerEmail" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Email">
                    
                    </div>
                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Contact Number
                        </label>
                        <input v-model="customerContact" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Customer number">
                    </div>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Customer address
                    </label>
                    <input v-model="customerAddress" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter address here">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Sales lead
                    </label>
                    <select v-model="salesLead" class="form-select mt-1 block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                        <option>Aishwarya Kaul</option>
                        <option>Sushma</option>
                        <option>Aishwarya Subramanyam</option>
                        <option>Oishik Ghosh</option>
                    </select>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Product ordered
                    </label>
                    <input v-model="productOrdered" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Product/'s">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Status of the order
                    </label>
                    <select v-model="status" class="form-select mt-1 block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                        <option>In progress</option>
                        <option>On Hold</option>
                        <option>Completed</option>
                        <option>Payment Awaited</option>
                    </select>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Order Date
                    </label>
                    <input v-model="orderDate" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter date ordered">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Delivery date
                    </label>
                    <input v-model="etaDate" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter delivery date">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Balance pending
                    </label>
                    <input v-model="balancePending" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter balance pending by client">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Vendor
                    </label>
                    <input v-model="vendorName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Vendor">
                </div>
                <div class="w-full mb-2">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Selling price
                    </label>
                    <input v-model="sellingPrice" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="number" placeholder="Selling price">
                </div>
                <div class="py-4">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div v-for="(expense, index) in expenses" :key="index" class="flex flex-wrap -mx-3">
                    <div class="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Expense head
                        </label>
                        <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="expense.expenseHead" type="text" placeholder="Expense head">
                    </div>
                    <div class="w-full flex flex-wrap md:w-1/2 mb-2 md:mb-0">

                        <div class="w-4/5 px-3 mb-0 md:mb-0">
                            <div class="">
                                <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Value
                                </label>
                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="expense.value"  type="number" placeholder="Value">
                            </div>
                        </div>

                        <button type="button" @click="removeExpense(index)" class="w-1/6 h-12 mt-6 mr-2 py-2  border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                            DEL
                        </button>

                    </div>
                    <div class="w-full px-3 flex flex-wrap md:w-1/2 mb-2 md:mb-0">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Advance
                        </label>
                        <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="expense.advance"  type="number" placeholder="Advance">
                    </div>
                    <div class="w-full px-3 flex flex-wrap md:w-1/2 mb-2 md:mb-0">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Balance
                        </label>
                        <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="number" readonly :value="expense.value - expense.advance">
                    </div>
                    
                </div>
                <button type="button" @click="addExpense" class="w-full h-10 mt-6 mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                    Add expense
                </button>
                <div class="flex mb-6 mt-4 justify-center">
                    <div class="mb-3 w-full">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-md font-bold mb-2" >
                            Create order image gallery
                        </label>
                        <div class="flex -mx-3">
                            
                            <input @change="onClickFile" id="file" ref="myFiles" accept="image/*" class="block w-4/5 mx-3 cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" type="file">
                            
                            <div class="w-1/5 px-3 mb-2 md:mb-0">
                                <button @click="addToGallery()" type="button"  class="px-3 w-full py-1 block justify-center items-center border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                    <div class="text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 mx-auto w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-for="(image, index) in gallery" :key="index">
                    <div class="flex flex-wrap w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <img @click="delImage(index)" alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                            :src="image.url"/>
                        </div>
                    </div>
                </div>

                <div class="py-0 mb-2">
                    <div class="w-full border-t border-gray-300"></div>
                </div>

                <div class="w-full mb-2 md:mb-0">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Remarks
                    </label>
                    <textarea rows="4" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="remarks" type="text" placeholder="Remarks"></textarea>
                </div>

                <div class="py-0 mb-2">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <button type="button" @click="addOrder" class="w-full h-10 mt-6 mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-700 hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                    Add order to the system
                </button>
                

<!--                 <div>
                    <div class="flex  justify-center">
                        <div class="mb-3 w-full">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Upload invoice
                            </label>
                            <div class="flex">
                                <input class="block w-4/5 cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" aria-describedby="user_avatar_help" id="user_avatar" type="file">
                                <button type="button" @click="" class="w-1/6 justify-center items-center flex border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                    <div class="text-center">
                                        <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg>
                                    </div>
                                    
                                </button>
                            </div>
                    
                        </div>
                    </div>
                </div>
                <div class=" my-4">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" aria-describedby="user_avatar_help" id="user_avatar" type="file">
                                <button type="button" @click="" class="w-1/6 justify-center items-center flex border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                    <div class="text-center">
                                        <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg>
                                    </div>
                                    
                                </button>
                            </div>
                    
                        </div>
                    </div>
                </div>
                <div class=" my-4">
                    <div class="w-full border-t border-gray-300"></div>
                </div> -->
            <!-- <button @click="rectifyOrders()">Rectify orders</button> -->
            </form>
        </div>
    </div>
</template>

<script>
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { collection, addDoc, getFirestore, setDoc, doc, getDoc, getDocs } from "firebase/firestore"
import emailjs from '@emailjs/browser';
import axios from 'axios'
export default {
    data(){
        return{
            remarks:'',
            iCntr:1,
            orders:[],
            user: null,
            expenses:[
                {
                    expenseHead: '',
                    value: '', 
                    advance:'',
                    balance:''                   
                }
            ],
            orderId: '',
            customerName: '',
            customerEmail: '',
            customerContact: '',
            productOrdered: '',
            vendorName: '',
            sellingPrice:'',
            customerAddress: '',
            orderDate:'',
            status:'',
            balancePending:'',
            etaDate:'',
            gallery:[],
            emailBy:'',
            userRestricted: false,
            salesLead:'',
        }
    },
    async created(){
        try{
            
            var that = this
            const auth = getAuth();
            const db = getFirestore()
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    const uid = user.uid;
                    this.emailBy = user.email
                    ///alert(uid)
                    // ...
                } else {
                    // User is signed out
                    // ...
                }
            });
            if(this.emailBy === 'sales@trulysofas.com'){
                this.userRestricted = true
            }
            const docRef = doc(db, "ordersSnap", "snapshot");
            const docSnap = await getDoc(docRef);
            //this.orders = docSnap.data()
            var dataSnap = docSnap.data()
            var dataLength = Object.keys(dataSnap).length
            ///that.orders.push(dataSnap[0])
            
            for (let index = 0; index < dataLength; index++) {
                that.orders.push(dataSnap[index]);
                
            }
            //console.log(that.orders)
            if (docSnap.exists()) {
            console.log("Document data:");
            } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            }
        }
        catch(e){
            console.log(e)
        }
    },
    methods:{
        sendEmail(){
            emailjs.send('service_i0krftm', 'template_ukebw9r', {order_number:'test123', order_email:'email@email.com'}, '2VKjYxfSnWiMedzk5')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                }, (error) => {
                    console.log('FAILED...', error.text);
                });
        },
        async rectifyOrders(){
            try{
                //console.log(this.orders)
                var that = this
                const db = getFirestore()
                const querySnapshot = await getDocs(collection(db, "orders"));
                await querySnapshot.forEach((doc) => {
                    var order = doc.data()
                    order.id = doc.id
                    //console.log(order)
                    that.orders.push(order)
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                });
                const snapshot = Object.assign({}, that.orders)
                //console.log(snapshot)
                await setDoc(doc(db, "ordersSnap", "snapshot"), snapshot)
            }catch(error){
                console.log(error)
            } 
        },
        async addToGallery(){
            try {
                if(!this.orderId){
                    alert('Enter order Id to create gallery')
                    return
                }
                const storage = getStorage();
                const ext = this.file.name.slice(this.file.name.lastIndexOf('.'))
                var imgName = this.orderId + '_' + this.iCntr + ext
                const storageRef = ref(storage, 'products/'+ imgName)
                await uploadBytes(storageRef, this.file).then((snapshot) => {
                    console.log('Uploaded image to product gallery');
                    //console.log(snapshot)
                })
                await getDownloadURL(storageRef).then((downloadURL) => {
                    var newImage = {
                        name: imgName,
                        url: downloadURL
                    }
                    this.gallery.push(newImage)
                    this.iCntr = this.iCntr + 1
                    this.$refs.myFiles.value = null
                    console.log('File uploaded');
                });
            } catch (error) {
                
            }
            
        },
        onClickFile(){
            this.file = this.$refs.myFiles.files[0]
        },
        async delImage(index){
            try {
                const storage = getStorage()
                const nameref = 'products/' + this.gallery[index].name
                const desertRef = ref(storage, nameref);
                deleteObject(desertRef).then(() => {
                    console.log('image deleted')
                }).catch((error) => {
                    console.log(error)
                });
                this.gallery.splice(index, 1)
            } catch (error) {
                
            }
        },
        addExpense(){
            this.expenses.push({
                expenseHead: '',
                value: '',
                advance:'',
                balance:''
            })
            //console.log(this.expenses)
        },
        removeExpense(index){
            this.expenses.splice(index, 1)
        },
        async addOrder(){
            try {
                
                var that = this
                var today = new Date().toLocaleDateString()

                const db = getFirestore()
                const docRef = await addDoc(collection(db, "orders"), {
                    orderId: this.orderId,
                    customerName: this.customerName,
                    customerEmail: this.customerEmail,
                    customerContact: this.customerContact,
                    productOrdered: this.productOrdered,
                    vendorName: this.vendorName,
                    sellingPrice:this.sellingPrice,
                    expenses: this.expenses,
                    customerAddress: this.customerAddress,
                    orderDate: this.orderDate,
                    status: this.status,
                    date: today,
                    balancePending: this.balancePending,
                    etaDate: this.etaDate,
                    gallery: this.gallery,
                    remarks: this.remarks,
                    salesLead: this.salesLead
                });
                var order = {
                    orderId: this.orderId,
                    customerName: this.customerName,
                    customerEmail: this.customerEmail,
                    customerContact: this.customerContact,
                    productOrdered: this.productOrdered,
                    vendorName: this.vendorName,
                    sellingPrice:this.sellingPrice,
                    expenses: this.expenses,
                    id: docRef.id,
                    date: today,
                    customerAddress: this.customerAddress,
                    orderDate: this.orderDate,
                    status: this.status,
                    balancePending: this.balancePending,
                    etaDate: this.etaDate,
                    gallery: this.gallery,
                    remarks: this.remarks,
                    salesLead: this.salesLead
                }
                const res = await axios.post( process.env.VUE_APP_SLACK_MESSAGE, {
                    orderId: this.orderId,
                    customerName: this.customerName,
                    customerEmail: this.customerEmail,
                    customerContact: this.customerContact,
                    vendor: this.vendorName,
                    productOrdered: this.productOrdered,
                    remarks: this.remarks,
                    status: this.status,
                })
                console.log(res)
                await emailjs.send('service_i0krftm', 'template_ukebw9r', { order_number:this.orderId, order_email: this.emailBy, status: this.status, remarks: this.remarks }, '2VKjYxfSnWiMedzk5')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                }, (error) => {
                    console.log('FAILED...', error.text);
                });
                that.orders.push(order)
                //console.log(that.orders)
                ///var snapshot = that.orders
                const snapshot = Object.assign({}, that.orders)
                await setDoc(doc(db, "ordersSnap", "snapshot"), snapshot)
                this.$router.push({ name: 'OrderTable' })

            console.log("Document written");
            } catch (e) {
            console.error("Error adding document: ", e);
            }

        }
    }
}
</script>

