<template>
  <Navbar :key="$route.fullPath"></Navbar>
  <v-content>
    <router-view></router-view>
  </v-content>
</template>

<script>
import Navbar from '@/components/Navbar'
export default {
  data(){
    return{

    }
  },
  components: {
    Navbar,
  },
  methods:{
    logout(){
      alert('logged')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
