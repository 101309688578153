<template>
    <div>
      <h1 class="text-center text-5xl font-serif font-bold py-4">Truly Sofas Admin page</h1>
      <div class="grid  md:grid-cols-2 lg:grid-cols-4 gap-y-4 gap-x-4 grid-cols-1 py-8 mx-4">
        <div class="bg-yellow-200 inline-block px-4 py-4 rounded-xl max-w-full w-full md:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;">
            <!-- service block -->
            <div class="py-8 px-12 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                    <!-- icon -->
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-12 h-12 bi bi-clipboard2-check-fill" viewBox="0 0 16 16">
                      <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z"/>
                      <path d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5c0-.175.03-.344.085-.5Zm6.769 6.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
                    </svg>
                </div>
                <h3 class="text-2xl leading-normal mb-2 font-semibold text-black">Orders</h3>
                <h3 class="text-5xl leading-normal font-bold text-black">{{tableLength}}</h3>
            </div>
            <!-- end service block -->
        </div>

        <div class="bg-rose-200  inline-block px-4  py-4 rounded-xl max-w-full w-full  md:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;">
            <!-- service block -->
            <div class="py-8 px-12 transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                    <!-- icon -->
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-12 h-12 bi bi-box2-fill" viewBox="0 0 16 16">
                  <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5ZM15 4.667V5H1v-.333L1.5 4h6V1h1v3h6l.5.667Z"/>
                </svg>
                </div>
                <h3 class="text-2xl leading-normal mb-2 font-semibold text-black">Products</h3>
                <h3 class="text-5xl leading-normal font-bold text-black">{{tableProductLength}}</h3>
            </div>
            <!-- end service block -->
        </div>
        <div v-if="userRestricted == false" class="bg-lime-200 inline-block px-4  py-4 rounded-xl max-w-full w-full md:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;">
            <!-- service block -->
            <div class="py-8 px-12 inline-block transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                    <!-- icon -->
                   <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-12 h-12 bi bi-cash-stack" viewBox="0 0 16 16">
                      <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                      <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
                    </svg>
                </div>
                <h3 class="text-2xl leading-normal mb-2 font-semibold text-black">Sales</h3>
                <h3 class="text-3xl leading-normal font-bold text-black">₹{{totalRevenue}}</h3>
            </div>
            <!-- end service block -->
        </div>
        <div v-if="userRestricted == false" class="bg-teal-300 inline-block px-4  py-4 rounded-xl max-w-full w-full md:px-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" style="visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: fadeInUp;">
            <!-- service block -->
            <div class="py-8 px-12 inline-block transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div class="inline-block text-gray-900 mb-4">
                    <!-- icon -->
                   <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-12 h-12 bi bi-wallet-fill" viewBox="0 0 16 16">
                      <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"/>
                      <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"/>
                    </svg>
                </div>
                <h3 class="text-2xl leading-normal mb-2 font-semibold text-black">Profit</h3>
                <h3 class="text-3xl leading-normal font-bold text-black">₹{{totalProfit}}</h3>
            </div>
            <!-- end service block -->
        </div>
      </div>

      <h1 class="text-center font-bold text-4xl">The Truly Sofas Calculator</h1>
      <div class="flex h-screen justify-center">
        
        <form class="w-full max-w-lg mx-2 mt-6" @submit.prevent>
          <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                      Metres of fabric
                  </label>
                  <input v-model="sqmtrFabric" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="number" placeholder="Enter value">
              
              </div>
              <div class="w-1/2 md:w-1/2 px-3">
                  <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                      Sq. ft for leather
                  </label>
                  <h1 class="text-left pt-2 border border-gray-700 rounded py-3 px-4 mb-3 leading-tight ">{{sqftLeather}}</h1>
              </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                      Cost per sqft
                  </label>
                  <input v-model="costLeather" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="number" placeholder="Enter value">
              
              </div>
              <div class="w-1/2 md:w-1/2 px-3">
                  <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                      Total leather cost
                  </label>
                  <h1 class="text-left pt-2 border border-gray-700 rounded py-3 px-4 mb-3 leading-tight ">{{totalLeather}}</h1>
              </div>
          </div>
        </form> 
      </div>  

    </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { collection, addDoc, getFirestore, setDoc, doc, getDoc } from "firebase/firestore"
export default {
  name: 'HomeView',
  data(){
    return{ 
      tableLength: '',
      totalProfit: '',
      totalRevenue:'',
      orders:[],
      tableProductLength:'',
      sqmtrFabric:'',
      costLeather:'',
      userRestricted : false

    }
  },
  computed:{ 
    sqftLeather() {
        let total = 0
        total = this.sqmtrFabric * 20
        if (total == 0){
          return 0
        }
        else{ 
          return total
        } 
    },
    totalLeather() {
        let total = 0
        total = this.costLeather * this.sqftLeather
        if (this.sqftLeather == 0){
          return 0
        }
        else{ 
          return Number(total)
        } 
    },
  },
  async created(){
    try{
        //console.log(month + '_' + dt)
        var that = this
        const auth = getAuth();
        const db = getFirestore()
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                if(user.email == 'sales@trulysofas.com'){
                    this.userRestricted = true
                }
                ///alert(uid)
                // ...
            } else {
                // User is signed out
                // ...
            }
        });
        const docRef = doc(db, "ordersSnap", "snapshot");
        const docSnap = await getDoc(docRef);
        //this.orders = docSnap.data()
        var dataSnap = docSnap.data()
        var dataLength = Object.keys(dataSnap).length
        this.tableLength = dataLength
        ///that.orders.push(dataSnap[0])
        const docProductRef = doc(db, "productsSnap", "snapshot");
        const docProductSnap = await getDoc(docProductRef);
        //this.orders = docSnap.data()
        var dataProductSnap = docProductSnap.data()
        var dataProductLength = Object.keys(dataProductSnap).length
        this.tableProductLength = dataProductLength
        
        for (let index = 0; index < dataLength; index++) {
            that.orders.push(dataSnap[index]);
            
        }
        
        for (let index = 0; index < that.orders.length; index++) {
            var totalExp = 0
            for (let i = 0; i < that.orders[index].expenses.length; i++) {
                totalExp = totalExp + Number(that.orders[index].expenses[i].value)
            }
            this.orders[index].totalExpenses = totalExp
            var profit = Number(that.orders[index].sellingPrice) - Number(totalExp)
            this.orders[index].profit = profit
        }
        var totProfit =0
        for (let i = 0; i < that.orders.length; i++) {
            totProfit = totProfit + Number(that.orders[i].profit)
        }
        this.totalProfit = totProfit

        var totRevenue = 0
        for (let i = 0; i < that.orders.length; i++) {
            totRevenue = totRevenue + Number(that.orders[i].sellingPrice)
        }
        this.totalRevenue = totRevenue
        if (docSnap.exists()) {
        console.log("Document data:");
        } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        }
    }
    catch(e){
        console.log(e)
    }
  },

  
}
</script>
