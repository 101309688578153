import { createStore } from 'vuex'

export default createStore({
  state: {
    order:null,
    product:null,
    userEmail: '',
    userRestricted: false,
    quotation: null
  },
  getters: {
  },
  mutations: {
    selectOrder(state, item){
      //state.cartCntr++
      state.order = item
    },
    selectProduct(state, item){
      //state.cartCntr++
      state.product = item
    },
    selectQuotation(state, item){
      //state.cartCntr++
      state.quotation = item
    },
    getUser(state, item){
      //state.cartCntr++
      state.userEmail = item
      //console.log(item)

    },
    restrictUser(state, item){
      state.userRestricted = item
      //console.log(state.userRestricted)
    }
  },
  actions: {
  },
  modules: {
  }
})
