import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/app.css'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import VueExcelXlsx from "vue-excel-xlsx";
import LoadScript from "vue-plugin-load-script";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAlQPhwar10o1BwmbDj4Gu4Bn3EOigMC6I",
  authDomain: "ldeez-vendor.firebaseapp.com",
  projectId: "ldeez-vendor",
  storageBucket: "ldeez-vendor.appspot.com",
  messagingSenderId: "232809360638",
  appId: "1:232809360638:web:505a16a2a0e57a8e0a09af",
  measurementId: "G-DXJFJNVZSB"
};

initializeApp(firebaseConfig);
const db = getFirestore();
export default db

createApp(App).use(store).use(router).use(VueExcelXlsx).use(LoadScript).mount('#app')
