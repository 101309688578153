<template>
    <div class="">
        <div class="flex h-screen justify-center">
            <form class="w-full max-w-lg mx-2 mt-6">
                <h1 class="text-3xl font-bold text-left mb-6">Add thryv product</h1>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full px-3">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Product Name
                        </label>
                        <input v-model="productName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Product name">
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-1/2 px-3 mb-6">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Collection
                        </label>
                        <select v-model="thryvcollection" class="form-select mt-1 block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                            <option>lip balm</option>
                            <option>skincare</option>
                        </select>
                    </div>
                    <div class="w-1/2 px-3 mb-6">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            is Active?
                        </label>
                        <select v-model="isActive" class="form-select mt-1 block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                            <option>yes</option>
                            <option>no</option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Price
                        </label>
                        <input v-model="sellingPrice" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="number" placeholder="Selling price">
                    
                    </div>
                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            MRP
                        </label>
                        <input v-model="productMrp" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="number" placeholder="Product MRP">
                    </div>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Poduct description
                    </label>
                    <textarea v-model="description" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter product description"></textarea>
                </div>

                <!-- <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Ingredients
                    </label>
                    <textarea v-model="ingredients" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter ingredients"></textarea>
                </div> -->
                <div>
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Ingredients
                    </label>
                    <editor v-model="ingredients" class='bg-gray-200 text-left mb-4 py-2 rounded-md text-gray-700 border border-black'  />

                    <!-- <div class="output-group">
                        <label>Content</label>
                        <code>{{ ingredients }}</code>
                    </div> -->
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Application
                    </label>
                    <textarea v-model="application" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Application"></textarea>
                </div>
                <!-- <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Benefits
                    </label>
                    <textarea v-model="benefits" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter benefits"></textarea>
                </div> -->
                <div>
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Benefits
                    </label>
                    <editor v-model="benefits" class='bg-gray-200 mb-4 py-2 rounded-md text-left text-gray-700 border border-black'  />

                    <!-- <div class="output-group">
                        <label>Content</label>
                        <code>{{ benefits }}</code>
                    </div> -->
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Options heading
                    </label>
                    <input v-model="optionsHeading" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter options heading">
                </div>
                <!-- <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Real results
                    </label>
                    <input v-model="realResults" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter options heading">
                </div> -->
                <div>
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Real results
                    </label>
                    <editor v-model="realResults" class='bg-gray-200 mb-4 py-2 rounded-md text-left text-gray-700 border border-black'  />

                    <!-- <div class="output-group">
                        <label>Content</label>
                        <code>{{ realResults }}</code>
                    </div> -->
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        subTitle
                    </label>
                    <input v-model="subTitle" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="enter subtitle">
                </div>
                <div class="flex mb-6 justify-center">
                    <div class="mb-3 w-full">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-md font-bold mb-2" >
                            Create gallery
                        </label>
                        <div class="flex -mx-3">
                            
                            <input @change="onClickFile" id="file" ref="myFiles" accept="image/*" class="block w-4/5 mx-3 cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" type="file">
                            
                            <div class="w-1/5 px-3 mb-2 md:mb-0">
                                <button @click="addToGallery()" type="button"  class="px-3 w-full py-1 block justify-center items-center border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                    <div class="text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-for="(image, index) in gallery" :key="index">
                    <div class="flex flex-wrap w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <img @click="delImage(index)" alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                            :src="image.url"/>
                        </div>
                    </div>
                </div>

                <div class="py-0 mb-6">
                    <div class="w-full border-t border-gray-300"></div>
                </div>


                <div class="w-full mb-6 ">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-md font-bold mb-4">
                        Add options
                    </label>
                     <div class="flex flex-wrap -mx-3">
                        <div class="w-1/2 px-3 mb-2 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Selling price
                            </label>
                            <input class="appearance-none block w-full text-sm bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="optionsSellingPrice" type="text" placeholder="Enter selling price">
                        </div>
                        <div class="w-1/2 px-3 mb-2 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Mrp
                            </label>
                            <input class="appearance-none block w-full text-sm bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="optionsMrp" type="text" placeholder="Enter Mrp">
                        </div>
                        <div class="w-full md:w-1/3 px-3 mb-2 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Option name
                            </label>
                            <input class="appearance-none block w-full text-sm bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="optionsName" type="text" placeholder="Option name">
                        </div>
                        <div class="w-full flex flex-wrap md:w-2/3 mb-2 md:mb-0">
                            <div class="w-full px-3 mb-0 md:mb-0">
                                <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Image file
                                </label>
                                <input class="appearance-none block text-xs w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" @change="onClickFile2" id="file2" ref="myFiles2" accept="image/*"  type="file" placeholder="Value">
                            </div>
                        </div>
                        <img v-if="imageUrl" :src="imageUrl" class="h-24 px-3" alt="">

                    </div>
                    <div v-for="(option, index) in options" :key="index" class="flex flex-wrap">
                        <div class="w-1/4">
                            <img :src="option.url" alt="">
                        </div>
                        <div class="w-3/4 px-4 text-left">
                            <h1>Name: {{option.name}}</h1>
                            <h1>Selling Price: {{option.sellingPrice}}</h1>
                            <h1>Mrp: {{option.mrp}}</h1>
                        </div>
                        <button @click="delOption(index)" class="w-full h-12 mt-4 mb-6 mx-3 py-2  border border-transparent text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Delete option</button>
                    </div>
                    <button type="button" @click="addOption" class="w-full h-12 mt-6 mb-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                        Add option
                    </button>
                </div>


                <div class="py-0 mb-6">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                

                <div class="w-full my-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Product tags(comma seperated)
                    </label>
                    <input v-model="tags" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter product tags">
                </div>

                <button type="button" @click="addProduct" class="w-full font-bold h-12 mt-6 mb-4 py-2 px-4 border border-transparent text-sm rounded-md text-white bg-sky-700 hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                    Add product to the system
                </button>

            </form>
        </div>
    </div>
</template>

<script>
import Editor from './Editor.vue'
import axios from "axios"
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { collection, addDoc, getFirestore, setDoc, doc, getDoc } from "firebase/firestore"
export default {
    components: {
        Editor,
    },
    data(){
        return{
            products:[],
            tags:'',
            user: null,
            gallery:[],
            iCntr:1,
            cCntr:0,
            leadTime:'',
            productOption:'',
            options:[

            ],
            productType:null,
            optionsSellingPrice:'',
            optionsMrp:'',
            optionsUrl:'',
            optionsName:'',
            productOptions:[],
            productColor:'',
            productColors:[],
            orderId: '',
            productName: '',
            sellingPrice: '',
            productMrp: '',
            description: '',
            vendorName: '',
            imageUrl:'',
            selectedUrl:'',
            subTitle:'',
            ingredients:'',
            optionsHeading:'',
            benefits:'',
            application:'',
            realResults:'',
            thryvcollection:'',
            isActive:'',
            content: '',
        }
    },
    async created(){
        try{
            var that = this
            const auth = getAuth();
            const db = getFirestore()
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    const uid = user.uid;
                    ///alert(uid)
                    // ...
                } else {
                    // User is signed out
                    // ...
                }
            });

            const res = await axios.get( process.env.VUE_APP_GET_PRODUCT );
            this.products = res.data
            console.log(this.products)
        }
        catch(e){
            console.log(e)
        }
    },
    methods:{
        async addOption(){
            try {
                if(!this.productName){
                    alert('Enter product name to add option')
                    return
                }
                if(!this.optionsName || !this.optionsSellingPrice || !this.optionsMrp || !this.imageUrl){
                    alert('Fill all fields')
                    return
                }
                const storage = getStorage();
                const ext = this.file2.name.slice(this.file2.name.lastIndexOf('.'))
                var imgName = this.productName + '_' + this.optionsName + ext
                const storageRef = ref(storage, 'products/'+ imgName)
                await uploadBytes(storageRef, this.file2).then((snapshot) => {
                    console.log('Uploaded image to product gallery');
                    //console.log(snapshot)
                })
                await getDownloadURL(storageRef).then((downloadURL) => {
                    this.selectedUrl = downloadURL
                    console.log('File uploaded');
                });
                var option = {
                    name: this.optionsName,
                    sellingPrice : this.optionsSellingPrice,
                    mrp: this.optionsMrp,
                    url : this.selectedUrl,
                    imageName: imgName
                }
                this.options.push(option)
                this.$refs.myFiles2.value = null
                this.imageUrl = ''
                this.optionsSellingPrice = ''
                this.optionsMrp = ''
                this.optionsName = ''
            } catch (error) {
                
            }

            //console.log(this.expenses)
        },
        addToOptions(){
            this.productOptions.push(this.productOption)
            this.productOption = ''
        },
        delOption(index){
            try {
                const storage = getStorage()
                const nameref = 'products/' + this.options[index].imageName
                const desertRef = ref(storage, nameref);
                deleteObject(desertRef).then(() => {
                    console.log('image deleted')
                }).catch((error) => {
                    console.log(error)
                });
                this.options.splice(index, 1)
            } catch (error) {
                
            }
            
        },
        async delImage(index){
            try {
                const storage = getStorage()
                const nameref = 'products/' + this.gallery[index].name
                const desertRef = ref(storage, nameref);
                deleteObject(desertRef).then(() => {
                    console.log('image deleted')
                }).catch((error) => {
                    console.log(error)
                });
                this.gallery.splice(index, 1)
            } catch (error) {
                
            }
        },
        onClickFile(){
            this.file = this.$refs.myFiles.files[0]
            const ext = this.file.name.slice(this.file.name.lastIndexOf('.'))
        },
        onClickFile2(){
            this.file2 = this.$refs.myFiles2.files[0]
            const fr = new FileReader();
                fr.readAsDataURL(this.file2)
                fr.addEventListener("load", () => {
                this.imageUrl = fr.result
            });
            const ext = this.file2.name.slice(this.file2.name.lastIndexOf('.'))
        },
        onClickFile3(){
            this.file3 = this.$refs.myFiles3.files[0]
            const ext = this.file3.name.slice(this.file3.name.lastIndexOf('.'))
        },
        onClickFile4(){
            this.file4 = this.$refs.myFiles4.files[0]
            const ext = this.file4.name.slice(this.file4.name.lastIndexOf('.'))
        },
        async addToGallery(){
            try {
                if(!this.productName){
                    alert('Enter product name to create gallery')
                    return
                }
                const storage = getStorage();
                const ext = this.file.name.slice(this.file.name.lastIndexOf('.'))
                var imgName = this.productName + '_' + this.iCntr + ext
                const storageRef = ref(storage, 'products/'+ imgName)
                await uploadBytes(storageRef, this.file).then((snapshot) => {
                    console.log('Uploaded image to product gallery');
                    //console.log(snapshot)
                })
                await getDownloadURL(storageRef).then((downloadURL) => {
                    var newImage = {
                        name: imgName,
                        url: downloadURL
                    }
                    this.gallery.push(newImage)
                    this.iCntr = this.iCntr + 1
                    this.$refs.myFiles.value = null
                    console.log('File uploaded');
                });
            } catch (error) {
                
            }
            
        },

        async addProduct(){
            try {
                
                var that = this
                var today = new Date().toLocaleDateString()
                var slug = this.product.replace(/\s+/g, '-')
                var product = {
                    productName: this.productName,
                    slug: slug,
                    subTitle: this.subTitle,
                    sellingPrice: this.sellingPrice,
                    productMrp: this.productMrp,
                    description: this.description,
                    vendorName: this.vendorName,
                    options: this.options,
                    gallery: this.gallery,
                    dateModified: today,
                    tags: this.tags,
                    //id: docRef.id,
                    ingredients: this.ingredients,
                    optionsHeading: this.optionsHeading,
                    benefits: this.benefits,
                    application: this.application,
                    realResults: this.realResults,
                    thryvcollection: this.thryvcollection,
                    isActive: this.isActive,
                }
                const res = await axios.post( process.env.VUE_APP_ADD_THRYVPRODUCT, {
                    productName: this.productName,
                    slug: slug,
                    subTitle: this.subTitle,
                    sellingPrice: this.sellingPrice,
                    productMrp: this.productMrp,
                    description: this.description,
                    vendorName: this.vendorName,
                    options: this.options,
                    gallery: this.gallery,
                    dateModified: today,
                    tags: this.tags,
                    //id: docRef.id,
                    ingredients: this.ingredients,
                    optionsHeading: this.optionsHeading,
                    benefits: this.benefits,
                    application: this.application,
                    realResults: this.realResults,
                    thryvcollection: this.thryvcollection,
                    isActive: this.isActive,
                })
                console.log(res)
                /* that.products.push(product)
                //console.log(that.orders)
                ///var snapshot = that.orders
                const snapshot = Object.assign({}, that.products)
                await setDoc(doc(db, "productsSnap", "snapshot"), snapshot) */
                this.$router.push({ name: 'ViewProducts' })

            console.log("Document written");
            } catch (e) {
            console.error("Error adding document: ", e);
            }

        }
    }
}
</script>