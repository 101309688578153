<template>
    <div class="container mx-auto px-4 sm:px-8">
        <div class="py-8">
            <div>
                <h2 class="text-3xl font-bold leading-tight">Orders</h2>
            </div>
            <div class="my-2 flex sm:flex-row flex-col">
                <div class="flex flex-row mb-1 sm:mb-0">
                    <div class="relative">
                        <select
                            v-model="dtFilter" class="form-select appearance-none h-full rounded-l border block  w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option value="all">All</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                        </select>
                        
                        <!-- <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div> -->
                    </div>
                    <div class="relative">
                        <select
                            v-model="statusFilter" class="form-select appearance-none h-full border block  w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                            <option value="all">All</option>
                            <option value="In progress">In progress</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Completed">Completed</option>
                            <option value="Payment Awaited">Payment Awaited</option>
                        </select>
                    </div>  
                </div>
                <div class="block relative">
                    <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
                            <path
                                d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                            </path>
                        </svg>
                    </span>
                    <input placeholder="Search" v-model="searchTerm"
                        class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
                </div>
                <div class="block relativ align-left justtify-center">
                    <vue-excel-xlsx
                        v-if="userRestricted == false"
                        :data="this.statusFilterTable"
                        :columns="columns"
                        :file-name="'TS_orders'"
                        :file-type="'xlsx'"
                        :sheet-name="'orders'"
                        class="bg-black px-4 py-2 text-white rounded block"
                        >
                        Download
                    </vue-excel-xlsx>
                </div>
                <div class="block mx-2 relativ align-left justtify-center">
                    <vue-excel-xlsx
                        :data="this.statusFilterTable"
                        :columns="columnsOps"
                        :file-name="'TS_orders'"
                        :file-type="'xlsx'"
                        :sheet-name="'orders'"
                        class="bg-black px-4 py-2 text-white rounded block"
                        >
                        Download ops
                    </vue-excel-xlsx>
                </div>
                <div class="block mx-2 relativ align-left justtify-center">
                    <button @click="generateReport()" class="bg-black px-4 py-2 text-white rounded block"> Slack report</button>
                </div>
            </div>
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table class="min-w-full leading-normal">
                        <thead>
                            <tr>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Order No.
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Client Name
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Vendor
                                </th>
                                <th
                                    v-if="userRestricted == false" class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Selling price
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Total Expense
                                </th>
                                <th
                                    v-if="userRestricted == false" class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Profit
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Date
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Delivery date
                                </th>
                                <th
                                    v-if="userRestricted == false" class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Balance 
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Status
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, index) in statusFilterTable" :key="index">
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm" v-html="highlightMatches(order.orderId)">
                                    
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm" v-html="highlightMatches(order.customerName)">
                                    
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm" >
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {{order.vendorName}}
                                    </p>
                                </td>
                                <td v-if="userRestricted == false" class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap">{{order.sellingPrice}}</p>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap">{{order.totalExpenses}}</p>
                                </td>
                                <td v-if="userRestricted == false" class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap">{{order.profit}}</p>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap">{{order.orderDate}}</p>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap">{{order.etaDate}}</p>
                                </td>
                                <td v-if="userRestricted == false" class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap">{{order.balancePending}}</p>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap">{{order.status}}</p>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <button @click="viewOrder(order)" class="text-sky-500 font-bold whitespace-no-wrap">View</button>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <button @click="editOrder(order)" class="text-fuchsia-500 font-bold whitespace-no-wrap">Edit</button>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <div
                        class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                        <span class="text-xs xs:text-sm text-gray-900">
                            Showing 1 to 4 of {{tableLength}} Entries
                        </span>
                        <div class="inline-flex mt-2 xs:mt-0">
                            <button
                                class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                                Prev
                            </button>
                            <button
                                class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                                Next
                            </button>
                        </div>
                    </div>
                    <div v-if="userRestricted == false" class="text-right">
                        <h1 class="text-lg pr-6 font-bold">Total sales: ₹{{totalSales}}</h1>
                    </div>
                    <div v-if="userRestricted == false" class="text-right">
                        <h1 class="text-lg pr-6 font-bold">Total profit: ₹{{totalProfitTable}}</h1>
                    </div>

                </div>
            </div>
        </div>


        <div v-if="dialog" class="bg-transparent flex bg-opacity-30 absolute top-10 right-0 left-0 justify-center items-center" id="overlay">
            <div class="bg-gray-200 w-full mx-2 md:w-2/3 lg:w-1/2 py-2 px-3 rounded shadow-xl text-gray-800">
                <div class="flex justify-between items-center">
                    <svg @click="dialog = !dialog" class="h-6 w-6 cursor-pointer p-1 hover:bg-gray-300 rounded-full" id="close-modal" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"></path>
                    </svg>
                </div>
                <div class="mt-2 text-sm">
                    <h1 class="text-2xl text-left font-bold">Order no: {{order.orderId}}</h1>
                    <div class="flex justify-between">
                        <h1 class="text-xl text-left font-bold">{{order.customerName}}</h1>
                        <h1 v-if="order.orderDate" class="text-normal text-right ">Date: {{order.orderDate}}</h1>
                    </div>
                    <h1 class="text-normal text-left">{{order.customerEmail}}</h1>
                    <h1 class="text-normal text-left">{{order.customerContact}}</h1>
                    <h1 v-if="order.address" class="text-normal text-left">{{order.address}}</h1><br><br>
                    <h1 class="text-xl text-center underline">Product: {{order.productOrdered}}</h1><br><br>
                    <div v-if="order.gallery" class="grid grid-cols-6">
                        <div v-for="(image, index) in order.gallery" :key="index" class="">
                            <div class=" mx-1">
                                <a class="w-full p-1 md:p-2" :href="image.url" target="_blank">
                                <img alt="gallery"  class="block object-cover object-center w-32 h-32 rounded-lg"
                                    :src="image.url"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="userRestricted == false" class="flex"> 
                        <h1 class="text-lg w-1/2 font-bold text-left">Invoice value:</h1>
                        <h1 class="text-lg w-1/2 text-left">₹{{order.sellingPrice}}</h1>
                    </div><br>
                    <div class="flex" v-if="order.etaDate"> 
                        <h1 class="text-lg w-1/2 font-bold text-left">Delivery date:</h1>
                        <h1 class="text-lg w-1/2 text-left">₹{{order.etaDate}}</h1>
                    </div><br>
                    <div class="flex" v-if="order.balancePending && (userRestricted == false)"> 
                        <h1 class="text-lg w-1/2 font-bold text-left">Balance pending:</h1>
                        <h1 class="text-lg w-1/2 text-left">₹{{order.balancePending}}</h1>
                    </div><br>
                    <div class="py-1 my-0">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <h1 class="text-left text-lg font-bold">Expenses</h1>
                    <div class="flex">
                        <h1 class="text-md font-bold w-1/4 text-left">Expense:</h1>
                        <h1 class="text-md font-bold w-1/4 text-left">Total</h1>
                        <h1 class="text-md font-bold w-1/4 text-left">Advance</h1>
                        <h1 class="text-md font-bold w-1/4 text-left">Balance</h1>
                    </div>
                    <div class="flex" v-for="(expense, index) in order.expenses" :key="index"> 
                        <h1 class="text-md w-1/4 text-left">{{expense.expenseHead}}:</h1>
                        <h1 class="text-md w-1/4 text-left">₹{{expense.value}}</h1>
                        <h1 class="text-md w-1/4 text-left">₹{{expense.advance}}</h1>
                        <h1 class="text-md w-1/4 text-left">₹{{expense.value - expense.advance}}</h1>
                    </div>
                    <div class="flex"> 
                        <h1 class="text-lg font-bold w-1/2 text-left">Total Expenses:</h1>
                        <h1 class="text-lg w-1/2 text-left">₹{{expenseTotal}}</h1>
                    </div>
                    <div class="py-1 my-0">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <div v-if="userRestricted == false" class="flex"> 
                        <h1 v-if="userRestricted == false" class="text-lg font-bold w-1/2 text-left">Profit:</h1>
                        <h1 v-if="userRestricted == false" class="text-lg font-bold w-1/2 text-left">₹{{profitTotal}}</h1>
                    </div>
                    <div class="pb-6 my-0">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                </div>
                <div class="mt-3 flex justify-end space-x-3">
                    <a :href="order.PODUrl" v-if="order.PODUrl" target="_blank" class="px-3 py-1 rounded hover:bg-sky-300 hover:bg-opacity-50 hover:text-sky-900">POD</a>
                    <a :href="order.invoiceUrl" v-if="order.invoiceUrl" target="_blank" class="px-3 py-1 rounded hover:bg-sky-300 hover:bg-opacity-50 hover:text-sky-900">Invoice</a>
                    <button @click="editOrder(order)" class="px-3 py-1 rounded hover:bg-sky-300 hover:bg-opacity-50 hover:text-sky-900">Edit</button>
                    <button @click="dialog = !dialog" class="px-3 py-1 bg-red-800 text-gray-200 hover:bg-red-600 rounded">Close</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { collection, addDoc, getFirestore, setDoc, doc, getDoc } from "firebase/firestore"
import axios from 'axios'
export default {
    data(){
        return{
            orders:[],
            order:[],
            user:null,
            searchTerm:'',
            tableLength:'',
            dialog:false,
            dtFilter:'all',
            statusFilter: 'all',
            totalProfit: 0,
            columns : [
                {
                    label: "Order No",
                    field: "orderId",
                },
                {
                    label: "Customer",
                    field: "customerName",
                },
                {
                    label: "Vendor",
                    field: "vendorName",
                },
                {
                    label: "Product",
                    field: "productOrdered",
                },
                {
                    label: "Order Date",
                    field: "orderDate",
                },
                {
                    label: "Selling Price",
                    field: "sellingPrice",
                    ///dataFormat: this.priceFormat
                },
                {
                    label: "Total expenses",
                    field: "totalExpenses",
                    ///dataFormat: this.priceFormat
                },
                {
                    label: "Profit",
                    field: "profit",
                    ///dataFormat: this.priceFormat
                },
                {
                    label: "Status",
                    field: "status",
                },
            ],
            columnsOps : [
                {
                    label: "Order No",
                    field: "orderId",
                },
                {
                    label: "Customer",
                    field: "customerName",
                },
                {
                    label: "Vendor",
                    field: "vendorName",
                },
                {
                    label: "Product",
                    field: "productOrdered",
                },
                {
                    label: "Order Date",
                    field: "orderDate",
                },
                {
                    label: "Status",
                    field: "status",
                },
                {
                    label: "Description",
                    field: "description",
                    ///dataFormat: this.priceFormat
                },
            ],
            userEmail:'',
            userRestricted: false,
            noRemark : 0,
            noSellingData : 0,
            inProgress : 0,
            noDeliveryDate:0,
            restrictedEmails:['sales@trulysofas.com', 'narayansushma07@gmail.com' , 'rasaraf2206@gmail.com', 'oishikghosh@outlook.com']

        }
    },
    async created(){
        try{
            
            var that = this
            var date = new Date('03/12/2021')
            var month = date.getMonth() + 1
            var dt = date.getDate()
            //console.log(month + '_' + dt)
            var that = this
            const auth = getAuth();
            const db = getFirestore()
            await onAuthStateChanged(auth, (user) => {
                if (user) {
                    console.log(user.email)
                    /* if(user.email == 'sales@trulysofas.com'|| user.email == 'narayansushma07@gmail.com' || user.email == 'rasaraf2206@gmail.com'){
                        this.userRestricted = true
                        this.$store.commit('restrictUser', true)
                    } */
                    //console.log(this.userRestricted)
                    var doesInclude = this.restrictedEmails.includes(user.email.toLowerCase())
                    if(doesInclude) {
                        this.userRestricted = true
                        this.$store.commit('restrictUser', true)
                    }
                    console.log(this.userRestricted)
                  
                    
                                        // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    //const uid = user.uid;
                    // ...
                } else {
                    // User is signed out
                    // ...
                }
            });
            
            const docRef = doc(db, "ordersSnap", "snapshot");
            const docSnap = await getDoc(docRef);
            //this.orders = docSnap.data()
            var dataSnap = docSnap.data()
            var dataLength = Object.keys(dataSnap).length
            this.tableLength = dataLength
            ///that.orders.push(dataSnap[0])
            
            for (let index = 0; index < dataLength; index++) {
                that.orders.push(dataSnap[index]);
                
            }
            
            for (let index = 0; index < that.orders.length; index++) {
                var totalExp = 0
                for (let i = 0; i < that.orders[index].expenses.length; i++) {
                    totalExp = totalExp + Number(that.orders[index].expenses[i].value)
                }
                this.orders[index].totalExpenses = totalExp
                var profit = Number(that.orders[index].sellingPrice) - Number(totalExp)
                this.orders[index].profit = profit
            }
            var totProfit =0
            for (let i = 0; i < that.orders.length; i++) {
                totProfit = totProfit + Number(that.orders[i].profit)
            }
            this.totalProfit = totProfit
            if (docSnap.exists()) {
            console.log("Document data:");
            } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            }
        }
        catch(e){
            console.log(e)
        }
    },
    computed: {
        filteredRows() {
            return this.orders.filter(order => {
            const customerName = order.customerName.toString().toLowerCase();
            const orderNo = order.orderId.toLowerCase();
            const searchTerm = this.searchTerm.toLowerCase();

            return customerName.includes(searchTerm) ||
                orderNo.includes(searchTerm);
            });
        },
        monthFilter(){
            if(this.dtFilter == "All"){
                return this.filteredRows
            }
            return this.filteredRows.filter(order => {
            var date = new Date(order.orderDate)
            var month = date.getMonth() + 1
            var dt = date.getDate()

            return (month == Number(this.dtFilter)) || (this.dtFilter == "all")
            });
        },
        statusFilterTable(){
            if(this.statusFilter == "All"){
                return this.monthFilter
            }
            return this.monthFilter.filter(order => {
                return (order.status == this.statusFilter) || (this.statusFilter == "all")
            });
        },
        totalSales(){
            let total = 0
            for (let index = 0; index < this.statusFilterTable.length; index++) {
                total = total + Number(this.statusFilterTable[index].sellingPrice)
            }
            return total
        },
        
        expenseTotal(){
            let total = 0
            for (let index = 0; index < this.order.expenses.length; index++) {
                total = total + Number(this.order.expenses[index].value)
            }
            return total
        },
        totalProfitTable(){
            let total = 0
            for (let index = 0; index < this.statusFilterTable.length; index++) {
                total = total + Number(this.statusFilterTable[index].profit)
            }
            return total
        },
        profitTotal(){
            let total = 0
            total = Number(this.order.sellingPrice) - this.expenseTotal
            return total
        },
    },
    methods: {
        priceFormat(value){
            return '₹ ' + value;
        },
        async editOrder(order){
            try{
                await this.$store.commit("selectOrder", order)
                this.$router.push({ name: 'EditOrder' })
            }
            catch(e){
                console.log(e)
            }

        },
        viewOrder(order){
            this.order = order
            this.dialog = true
            window.scrollTo(0,0);

        },
        
        highlightMatches(text) {
            const matchExists = text.toLowerCase().includes(this.searchTerm.toLowerCase());
            if (!matchExists) return text;

            const re = new RegExp(this.searchTerm, 'ig');
            return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
        },
        async generateReport(){
            try {
                var that = this
                console.log('Starting')
                const res = await axios.post( process.env.VUE_APP_SLACK_STARTREPORT)
                console.log(res)
                for (let index = 0; index < this.orders.length; index++) {
                    if(this.orders[index].status === "In progress"){
                        //console.log(this.orders[index])
                        that.inProgress++
                        const res2 = await axios.post( process.env.VUE_APP_SLACK_REPORTITEM, {
                            orderId: this.orders[index].orderId,
                            customerName: this.orders[index].customerName,
                            vendor: this.orders[index].vendorName,
                            products: this.orders[index].productOrdered,
                            remarks: this.orders[index].remarks,
                            orderDate: this.orders[index].orderDate,
                        })
                        console.log(res2)
                        if(!this.orders[index].remarks){
                            that.noRemark++
                        }
                        if(!this.orders[index].sellingPrice){
                            that.noSellingData++
                        }
                        if(!this.orders[index].etaDate){
                            that.noDeliveryDate++
                        }
                    }      
                }
                const res3 = await axios.post( process.env.VUE_APP_SLACK_ENDREPORT, {
                    inProgress: this.inProgress,
                    noRemarks: this.noRemark,
                    noSales: this.noSellingData,
                    noDeliveryDate: this.noDeliveryDate

                })
                console.log(res3)
                console.log(this.inProgress)
                console.log(this.noRemark)
                console.log(this.noSellingData)


            } catch (error) {
                
            }
            

        }
    },

}
</script>