<template>
    <div class="container mx-auto px-4 sm:px-8">
        <div class="py-8">
            <div>
                <h2 class="text-3xl font-bold leading-tight">Quotations</h2>
            </div>
            <div class="my-2 flex sm:flex-row flex-col">

                <div class="block relative">
                    <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
                            <path
                                d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                            </path>
                        </svg>
                    </span>
                    <input placeholder="Search" v-model="searchTerm"
                        class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
                </div>
            </div>
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table class="min-w-full leading-normal">
                        <thead>
                            <tr>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Customer name
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Customer email
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Quote date
                                </th>
                                <th
                                    class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, index) in quotations" :key="index">
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm" v-html="highlightMatches(product.customerName)">
                                    
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm" v-html="highlightMatches(product.customerEmail)">
                                    
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm" >
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {{product.quoteDate}}
                                    </p>
                                </td>
                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-left text-sm">
                                    <button @click="editQuotation(product)" class="text-fuchsia-500 font-bold whitespace-no-wrap">Edit</button>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <div
                        class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                        <span class="text-xs xs:text-sm text-gray-900">
                            Showing 1 to 4 of {{tableLength}} Entries
                        </span>
                        <div class="inline-flex mt-2 xs:mt-0">
                            <button
                                class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                                Prev
                            </button>
                            <button
                                class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    data(){
        return{
            quotations:[],
            order:[],
            user:null,
            searchTerm:'',
            tableLength:'',
            dialog:false,
            dtFilter:'all',
            totalProfit: 0,
        }
    },
    async created(){
        try{
           /*  var that = this
            //console.log(process.env.VUE_APP_GET_PRODUCT)
            const auth = getAuth();
            const db = getFirestore()
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    const uid = user.uid;
                    ///alert(uid)
                    // ...
                } else {
                    // User is signed out
                    // ...
                }
            }); */

            const res = await axios.get( process.env.VUE_APP_GET_QUOTATIONS );
            console.log(res)
            this.quotations = res.data
            console.log(this.quotations)
        }
        catch(e){
            console.log(e)
        }
    },
    methods: {
        priceFormat(value){
            return '₹ ' + value;
        },
        async editQuotation(quotation){
            try{
                await this.$store.commit("selectQuotation", quotation)
                this.$router.push({ name: 'EditQuotation' })
                ///console.log(this.$store.state.product)

            }
            catch(e){
                console.log(e)
            }            
        },
        
        highlightMatches(text) {
            const matchExists = text.toLowerCase().includes(this.searchTerm.toLowerCase());
            if (!matchExists) return text;

            const re = new RegExp(this.searchTerm, 'ig');
            return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
        }
    },

}
</script>