<template>
    <div>
        <div class="flex h-screen justify-center">
            <form class="w-full max-w-lg mx-2 mt-6">
                <h1 class="text-3xl font-bold text-left mb-6">Add product</h1>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full px-3">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Product Name
                        </label>
                        <input v-model="productName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Product name">
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Price
                        </label>
                        <input v-model="sellingPrice" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="number" placeholder="Selling price">
                    
                    </div>
                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            MRP
                        </label>
                        <input v-model="productMrp" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="number" placeholder="Product MRP">
                    </div>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Poduct description
                    </label>
                    <textarea v-model="description" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter product description"></textarea>
                </div>
                <div class="flex mb-6 justify-center">
                    <div class="mb-3 w-full">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-md font-bold mb-2" >
                            Create gallery
                        </label>
                        <div class="flex -mx-3">
                            
                            <input @change="onClickFile" id="file" ref="myFiles" accept="image/*" class="block w-4/5 mx-3 cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" type="file">
                            
                            <div class="w-1/5 px-3 mb-2 md:mb-0">
                                <button @click="addToGallery()" type="button"  class="px-3 w-full py-1 block justify-center items-center border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                    <div class="text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-for="(image, index) in gallery" :key="index">
                    <div class="flex flex-wrap w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <img @click="delImage(index)" alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                            :src="image.url"/>
                        </div>
                    </div>
                </div>

                <div class="py-0 mb-6">
                    <div class="w-full border-t border-gray-300"></div>
                </div>

<!--                 <div class="flex mb-6 justify-center">
                    <div class="mb-3 w-full">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Add options
                        </label>
                        <div class="flex">
                            <input v-model="productOption" class="block w-4/5 cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" aria-describedby="user_avatar_help" type="text">
                            <button @click="addToOptions()" type="button"  class="w-1/6 justify-center items-center flex border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                <div class="text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="mb-4" >
                    <div class="bg-white rounded-lg shadow">
                        <ul class="divide-y-2 divide-gray-400" v-for="(option, index) in productOptions" :key="index">
                            <li @click="delOption(index)" class="p-3">{{option}}</li>
                        </ul>
                    </div>
                </div> -->

                <div class="w-full mb-6 ">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-md font-bold mb-4">
                        Add options
                    </label>
                     <div class="flex flex-wrap -mx-3">
                        <div class="w-1/2 px-3 mb-2 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Selling price
                            </label>
                            <input class="appearance-none block w-full text-sm bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="optionsSellingPrice" type="text" placeholder="Enter selling price">
                        </div>
                        <div class="w-1/2 px-3 mb-2 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Mrp
                            </label>
                            <input class="appearance-none block w-full text-sm bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="optionsMrp" type="text" placeholder="Enter Mrp">
                        </div>
                        <div class="w-full md:w-1/3 px-3 mb-2 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Option name
                            </label>
                            <input class="appearance-none block w-full text-sm bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="optionsName" type="text" placeholder="Option name">
                        </div>
                        <div class="w-full flex flex-wrap md:w-2/3 mb-2 md:mb-0">
                            <div class="w-full px-3 mb-0 md:mb-0">
                                <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Image file
                                </label>
                                <input class="appearance-none block text-xs w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" @change="onClickFile2" id="file2" ref="myFiles2" accept="image/*"  type="file" placeholder="Value">
                            </div>
                        </div>
                        <img v-if="imageUrl" :src="imageUrl" class="h-24 px-3" alt="">

                    </div>
                    <div v-for="(option, index) in options" :key="index" class="flex flex-wrap">
                        <div class="w-1/4">
                            <img :src="option.url" alt="">
                        </div>
                        <div class="w-3/4 px-4 text-left">
                            <h1>Name: {{option.name}}</h1>
                            <h1>Selling Price: {{option.sellingPrice}}</h1>
                            <h1>Mrp: {{option.mrp}}</h1>
                        </div>
                        <button @click.prevent="delOption(index)" class="w-full h-12 mt-4 mb-6 mx-3 py-2  border border-transparent text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Delete option</button>
                    </div>
                    <button type="button" @click="addOption" class="w-full h-12 mt-6 mb-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                        Add option
                    </button>
                </div>

                <!-- <div class="mb-4" v-for="(image, index) in productColors" :key="index">
                    <div class="flex flex-wrap w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <img @click="delOption(index)" alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                            :src="image.url"/>
                        <h1 class="text-left font-bold px-2">{{image.name}}</h1>
                        </div>
                    </div>
                </div> -->

                <div class="py-0 mb-6">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-md font-bold mb-4" for="grid-last-name">
                        Add colors
                    </label>
                    <div class="w-full  mb-0 md:mb-0">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Color Product Image
                        </label>
                        <input class="appearance-none block text-xs w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" @change="onClickFile4" id="file4" ref="myFiles4" accept="image/*"  type="file" placeholder="Value">
                    </div>
                    <div class="flex flex-wrap -mx-3">
                        <div class="w-full md:w-1/4 px-3 mb-2 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Color name
                            </label>
                            <input class="appearance-none block w-full text-sm bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="productColor" type="text" placeholder="Add color">
                        </div>
                        <div class="w-full flex flex-wrap md:w-3/4 mb-2 -mx-3 md:mb-0">
                            <div class="w-4/5 px-3  mb-0 md:mb-0">
                                <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Image file
                                </label>
                                <input class="appearance-none block text-xs w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" @change="onClickFile3" id="file3" ref="myFiles3" accept="image/*"  type="file" placeholder="Value">
                            </div>
                            <button type="button" @click="addColor()" class="w-1/6 h-12 mt-6 px-3 py-2  border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                Add
                            </button>

                        </div>
                    </div>
                </div>

                <div class="mb-4" v-for="(image, index) in productColors" :key="index">
                    <div class="flex flex-wrap">

                            <div
                                class="bg-cover bg-center mx-4 w-32 h-32 text-white object-fill"
                                :style="{ backgroundImage: `url(${image.url})`}"
                                @click="delColor(index)"
                            ></div>
                            <div
                                class="bg-cover bg-center mx-4 w-32 h-32 text-white object-fill"
                                :style="{ backgroundImage: `url(${image.imgUrl})`}"
                                @click="delColor(index)"
                                v-if="image.imgUrl"
                            ></div>
                        
                        

                        <h1 class="text-left mx-4 py-4 w-full font-bold px-2">{{image.name}}</h1>
                    </div>
                </div>

                <div class="py-0 mb-6">
                    <div class="w-full border-t border-gray-300"></div>
                </div>

                <div class="w-full my-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Lead time (days)
                    </label>
                    <input v-model="leadTime" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter date ordered">
                </div>
                <div class="w-full my-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Product tags(comma seperated)
                    </label>
                    <input v-model="tags" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter product tags">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Vendor
                    </label>
                    <input v-model="vendorName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Vendor">
                </div>

                <button type="button" @click="editProduct" class="w-full font-bold h-12 mt-6 mb-4 py-2 px-4 border border-transparent text-sm  rounded-md text-white bg-sky-700 hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                    Edit product: {{product.productName}}
                </button>
                <button type="button" @click="deleteProduct" class="w-full h-10 mt-6 mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                    Delete product: #{{product.productName}}
                </button>

            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { collection, addDoc, getFirestore, deleteDoc, setDoc, updateDoc, doc, getDoc } from "firebase/firestore"
export default {
    data(){
        return{
            products:[],
            product:[],
            tags:'',
            user: null,
            gallery:[],
            iCntr:1,
            cCntr:0,
            leadTime:'',
            productOption:'',
            options:[],
            optionsSellingPrice:'',
            optionsMrp:'',
            optionsUrl:'',
            optionsName:'',
            productOptions:[],
            productColor:'',
            productColors:[],
            orderId: '',
            productName: '',
            sellingPrice: '',
            productMrp: '',
            description: '',
            vendorName: '',
            imageUrl:'',
            selectedUrl:''
        }
    },
    async created(){
        try{
            var that = this
            that.product = this.$store.state.product
            //console.log(that.product._id)
            //console.log(that.product.id)
            this.productName = this.product.productName
            this.sellingPrice = this.product.sellingPrice
            this.productMrp = this.product.productMrp
            this.description = this.product.description
            this.vendorName = this.product.vendorName
            if(this.product.productColors){
                this.productColors = this.product.productColors
            }
            this.leadTime = this.product.leadTime
            this.gallery = this.product.gallery
            this.tags =  this.product.tags
            if(this.product.options){
                this.options = this.product.options
            }
            const auth = getAuth();
            const db = getFirestore()
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    const uid = user.uid;
                    ///alert(uid)
                    // ...
                } else {
                    // User is signed out
                    // ...
                }
            });
            const docRef = doc(db, "productsSnap", "snapshot");
            const docSnap = await getDoc(docRef);
            //this.orders = docSnap.data()
            var dataSnap = docSnap.data()
            var dataLength = Object.keys(dataSnap).length
            ///that.orders.push(dataSnap[0])
            
            for (let index = 0; index < dataLength; index++) {
                that.products.push(dataSnap[index]);     
            }
            //console.log(that.products)
            if (docSnap.exists()) {
            console.log("Document data:");
            } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            }
        }
        catch(e){
            console.log(e)
        }
    },
    methods:{
        async addOption(){
            try {
                if(!this.productName){
                    alert('Enter product name to add option')
                    return
                }
                if(!this.optionsName || !this.optionsSellingPrice || !this.optionsMrp || !this.imageUrl){
                    alert('Fill all fields')
                    return
                }
                const storage = getStorage();
                const ext = this.file2.name.slice(this.file2.name.lastIndexOf('.'))
                var imgName = this.productName + '_' + this.optionsName + ext
                const storageRef = ref(storage, 'products/'+ imgName)
                await uploadBytes(storageRef, this.file2).then((snapshot) => {
                    console.log('Uploaded image to product gallery');
                    //console.log(snapshot)
                })
                await getDownloadURL(storageRef).then((downloadURL) => {
                    this.selectedUrl = downloadURL
                    console.log('File uploaded');
                });
                var option = {
                    name: this.optionsName,
                    sellingPrice : this.optionsSellingPrice,
                    mrp: this.optionsMrp,
                    url : this.selectedUrl,
                    imageName: imgName
                }
                //console.log(option)
                this.options.push(option)
                //console.log(this.options)
                this.$refs.myFiles2.value = null
                this.imageUrl = ''
                this.optionsSellingPrice = ''
                this.optionsMrp = ''
                this.optionsName = ''
            } catch (error) {
                
            }

            //console.log(this.expenses)
        },

        async delOption(index){
            try {
                console.log('started')
                const storage = getStorage()

                const nameref = 'products/' + this.options[index].imageName
                const desertRef = ref(storage, nameref);
                await deleteObject(desertRef).then(() => {
                    console.log('image deleted')
                }).catch((error) => {
                    console.log(error)
                });

                this.options.splice(index, 1)
            } catch (error) {
                
            }
            
        },
        async delImage(index){
            try {
                const storage = getStorage()
                const nameref = 'products/' + this.gallery[index].name
                const desertRef = ref(storage, nameref);
                await deleteObject(desertRef).then(() => {
                    console.log('image deleted')
                }).catch((error) => {
                    console.log(error)
                });
                this.gallery.splice(index, 1)
            } catch (error) {
                
            }
        },
        async delColor(index){
            try {
                const storage = getStorage()
                const nameref = 'products/' + this.productColors[index].fileName
                const nameref2 = 'products/' + this.productColors[index].imgFileName
                const desertRef = ref(storage, nameref);
                const imgRef = ref(storage, nameref2 )
                await deleteObject(desertRef).then(() => {
                    console.log('image deleted')
                }).catch((error) => {
                    console.log(error)
                });
                await deleteObject(imgRef).then(() => {
                    console.log('image deleted')
                }).catch((error) => {
                    console.log(error)
                });
                this.productColors.splice(index, 1)
            } catch (error) {
                
            }
        },
        onClickFile(){
            this.file = this.$refs.myFiles.files[0]
            //console.log(this.file.name)
            const ext = this.file.name.slice(this.file.name.lastIndexOf('.'))
            //console.log(ext)
        },
        onClickFile2(){
            this.file2 = this.$refs.myFiles2.files[0]
            const fr = new FileReader();
                fr.readAsDataURL(this.file2)
                fr.addEventListener("load", () => {
                this.imageUrl = fr.result
            });
            //console.log(this.file2)
            const ext = this.file2.name.slice(this.file2.name.lastIndexOf('.'))
            //console.log(ext)
        },
        onClickFile3(){
            this.file3 = this.$refs.myFiles3.files[0]
        },
        onClickFile4(){
            this.file4 = this.$refs.myFiles4.files[0]
        },
        async addToGallery(){
            try {
                if(!this.productName){
                    alert('Enter product name to create gallery')
                    return
                }
                const storage = getStorage();
                const ext = this.file.name.slice(this.file.name.lastIndexOf('.'))
                var imgName = this.productName + '_' + this.iCntr + ext
                const storageRef = ref(storage, 'products/'+ imgName)
                await uploadBytes(storageRef, this.file).then((snapshot) => {
                    console.log('Uploaded image to product gallery');
                    //console.log(snapshot)
                })
                await getDownloadURL(storageRef).then((downloadURL) => {
                    var newImage = {
                        name: imgName,
                        url: downloadURL
                    }
                    this.gallery.push(newImage)
                    this.iCntr = this.iCntr + 1
                    this.$refs.myFiles.value = null
                    //console.log(this.gallery)
                    console.log('File uploaded');
                });
            } catch (error) {
                
            }
            
        },
        async addColor(){
            try {
                if(!this.productName){
                    alert('Enter product name to add color options')
                    return
                }
                if(this.productColor == ''){
                    alert('Add Color title')
                    return
                }
                if(!this.file3){
                    alert('Choose color image')
                    return
                }
                const storage = getStorage();
                if(this.file4){
                    const ext2 = this.file4.name.slice(this.file4.name.lastIndexOf('.'))
                    var imgName2 = this.productName + this.productColor + 'image_' + this.cCntr + ext2
                    const storageRef2 = ref(storage, 'products/'+ imgName2)
                    await uploadBytes(storageRef2, this.file4).then((snapshot) => {
                        console.log('Uploaded color option');
                        //console.log(snapshot)
                    })
                    await getDownloadURL(storageRef2).then((downloadURL2) => {
                        console.log('Color Image uploaded');
                        this.downloadUrl2 = downloadURL2
                    });
                }
                
                const ext = this.file3.name.slice(this.file3.name.lastIndexOf('.'))
                    var imgName = this.productName + this.productColor + '_' + this.cCntr + ext
                    const storageRef = ref(storage, 'products/'+ imgName)
                    await uploadBytes(storageRef, this.file3).then((snapshot) => {
                        console.log('Uploaded color option');
                        //console.log(snapshot)
                    })
                    await getDownloadURL(storageRef).then((downloadURL) => {
                        var newColor = {
                            fileName: imgName,
                            url: downloadURL,
                            name: this.productColor,
                            imgUrl: this.downloadUrl2,
                            imgFileName: imgName2
                        }
                        this.productColors.push(newColor)
                        this.cCntr = this.cCntr + 1
                        this.productColor = ''
                        this.$refs.myFiles3.value = null
                        this.$refs.myFiles4.value = null
                        console.log('File uploaded');
                    });
            } catch (error) {
                
            }
            
        },
        addExpense(){
            this.expenses.push({
                expenseHead: '',
                value: '',
            })
            //console.log(this.expenses)
        },
        removeExpense(index){
            this.expenses.splice(index, 1)
        },
        async editProduct(){
            try {
                //console.log(this.products[0])
                var that = this
                var today = new Date().toLocaleDateString()
                //const storage = getStorage();
                var dataLength = that.products.length
                for (let index = 0; index < dataLength; index++) {
                    if(that.products[index]._id == that.product._id){
                        //console.log(that.products[index].id)
                        that.products[index].productName = that.productName
                        that.products[index].sellingPrice = that.sellingPrice
                        that.products[index].productMrp = that.productMrp
                        that.products[index].description = that.description
                        that.products[index].vendorName = that.vendorName
                        that.products[index].productColors = that.productColors
                        that.products[index].options = that.options
                        that.products[index].leadTime = that.leadTime
                        that.products[index].gallery = that.gallery
                        that.products[index].dateModified = today
                        that.products[index].tags = that.tags
                        //console.log(that.products[index])
                    } 
                }
                const db = getFirestore()
                const snapshot = Object.assign({}, that.products)
                await setDoc(doc(db, "productsSnap", "snapshot"), snapshot)
               const docRef = doc(db, "products", that.product.id)
                await updateDoc(docRef, {
                    productName: this.productName,
                    sellingPrice: this.sellingPrice,
                    productMrp: this.productMrp,
                    description: this.description,
                    vendorName: this.vendorName,
                    productColors: this.productColors,
                    options: this.options,
                    leadTime: this.leadTime,
                    gallery: this.gallery,
                    dateModified: today,
                    tags: this.tags,
                });
                const res = await axios.put( process.env.VUE_APP_UPDATE_PRODUCT, {
                    productName: this.productName,
                    sellingPrice: this.sellingPrice,
                    productMrp: this.productMrp,
                    description: this.description,
                    vendorName: this.vendorName,
                    productColors: this.productColors,
                    options: this.options,
                    leadTime: this.leadTime,
                    gallery: this.gallery,
                    dateModified: today,
                    tags: this.tags,
                    _id : this.product._id,
                    id: this.product.id
                })
                console.log(res.data.message)
                this.$router.push({ name: 'ViewProducts' })
                console.log("Document written ");
            } catch (e) {
                //console.error("Error adding document: ", e);
            }

        },
        async deleteProduct(){
            try{
                var that = this

                const res = await axios.put( process.env.VUE_APP_DELETE_PRODUCT, {
                    id: this.product._id
                })
                console.log(res)
                const db = getFirestore()
                await deleteDoc(doc(db, "products", this.product.id))
                ///var dataLength = Object.keys(that.orders).length
           
                for (let index = 0; index < that.products.length; index++) {
                    if(that.products[index].id == that.product.id){
                        that.products.splice(index, 1)
                        ///console.log(that.ordersSnap)
                    }
                }
                const snapshot = Object.assign({}, that.products)
                await setDoc(doc(db, "productsSnap", "snapshot"), snapshot)
                this.$router.push({ name: 'ViewProducts' })
            }catch(e){
                console.log(e)
            }
        },
    }
}
</script>