import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '@/components/Login'
import AddOrder from '@/components/AddOrder'
import OrderTable from '@/components/OrderTable'
import EditOrder from '@/components/EditOrder'
import AddProduct from '@/components/AddProduct'
import ViewProducts from '@/components/ViewProducts'
import EditProduct from '@/components/EditProduct'
import CreateQuote from '@/components/CreateQuote'
import ViewQuotations from '@/components/ViewQuotations'
import EditQuotation from '@/components/EditQuotation'
import AddThryvProduct from '@/components/AddThryvProduct'
import ViewThryvProducts from '@/components/ViewThryvProducts'


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/createquote',
    name: 'CreateQuote',
    component: CreateQuote,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addorder',
    name: 'AddOrder',
    component: AddOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addproduct',
    name: 'AddProduct',
    component: AddProduct,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/viewproducts',
    name: 'ViewProducts',
    component: ViewProducts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addthryvproduct',
    name: 'AddThryvProduct',
    component: AddThryvProduct,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/viewthryvproducts',
    name: 'ViewThryvProducts',
    component: ViewThryvProducts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editproduct',
    name: 'EditProduct',
    component: EditProduct,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editquotation',
    name: 'EditQuotation',
    component: EditQuotation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editorder',
    name: 'EditOrder',
    component: EditOrder,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/viewquotations',
    name: 'ViewQuotations',
    component: ViewQuotations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/list',
    name: 'OrderTable',
    component: OrderTable,
    meta: {
      requiresAuth: true
    }
  },
  /* {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import( '../views/AboutView.vue')
    }
  } */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
