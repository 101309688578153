<template>
    <div>
        <div class="flex h-screen justify-center">
            <form class="w-full max-w-lg mx-2 mt-6">
                <h1 class="text-3xl pl-2 font-bold text-left mb-6">Edit order #{{order.orderId}}</h1>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Order Number
                        </label>
                        <input v-model="orderId" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Enter id">
                    
                    </div>
                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Customer name
                        </label>
                        <input v-model="customerName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Customer name">
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Customer Email
                        </label>
                        <input v-model="customerEmail" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Email">
                    
                    </div>
                    <div class="w-full md:w-1/2 px-3">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Contact Number
                        </label>
                        <input v-model="customerContact" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Customer number">
                    </div>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Cutomer Address
                    </label>
                    <input v-model="customerAddress" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter customer address here">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Product ordered
                    </label>
                    <input v-model="productOrdered" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Product/'s">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Sales lead
                    </label>
                    <select v-model="salesLead" class="form-select mt-1 block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                        <option>Aishwarya Kaul</option>
                        <option>Sushma</option>
                        <option>Aishwarya Subramanyam</option>
                        <option>Oishik Ghosh</option>
                    </select>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Order Date
                    </label>
                    <input v-model="orderDate" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Date product ordered on">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Delivery date
                    </label>
                    <input v-model="etaDate" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter delivery date">
                </div>
                <div class="w-full mb-6" v-if="userRestricted == false">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Balance pending
                    </label>
                    <input v-model="balancePending" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter balance pending by client">
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Status of the order
                    </label>
                    <select v-model="status" class="form-select mt-1 block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                        <option>In progress</option>
                        <option>On Hold</option>
                        <option>Completed</option>
                        <option>Payment Awaited</option>
                    </select>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Vendor
                    </label>
                    <input v-model="vendorName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Vendor">
                </div>
                <div v-if="userRestricted == false" class="w-full mb-2">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Selling price
                    </label>
                    <input v-model="sellingPrice" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="number" placeholder="Selling price">
                </div>
                <div class="py-4">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div v-for="(expense, index) in expenses" :key="index" class="flex flex-wrap -mx-3">

                    <div class="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Expense head
                        </label>
                        <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="expense.expenseHead" type="text" placeholder="Expense head">
                    </div>
                    <div class="w-full flex flex-wrap md:w-1/2 mb-2 md:mb-0">

                        <div class="w-4/5 px-3 mb-0 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Value
                            </label>
                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="expense.value"  type="number" placeholder="Value">
                        </div>
                        <button type="button" @click="removeExpense(index)" class="w-1/6 h-12 mt-6 mr-2 py-2  border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                            DEL
                        </button>

                    </div>
                    <div class="w-full px-3 flex flex-wrap md:w-1/2 mb-2 md:mb-0">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Advance
                        </label>
                        <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="expense.advance"  type="number" placeholder="Advance">
                    </div>
                    <div class="w-full px-3 flex flex-wrap md:w-1/2 mb-2 md:mb-0">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Balance
                        </label>
                        <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="number" readonly :value="expense.value - expense.advance">
                    </div>
                    <div class="w-full py-4 border-t border-gray-300"></div>
                    
                    
                </div>
                
                <button type="button" @click="addExpense" class="w-full h-10 mt-6 mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                    Add expense
                </button>
                <div class="py-0 mb-2">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                

                <div>
                    <div class="flex  justify-center">
                        <div class="mb-3 w-full">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Upload invoice
                            </label>
                            <div class="flex">
                                <input @change="onClickFile" id="file" ref="myFiles" accept="application/pdf" class="block w-4/5 cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" aria-describedby="user_avatar_help" type="file">
                                <a :href="order.invoiceUrl" target="_blank" v-if="order.invoiceUrl" type="button"  class="w-1/6 justify-center items-center flex border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                    <div class="text-center">
                                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd"></path></svg>
                                    </div>
                                    
                                </a>
                            </div>
                    
                        </div>
                    </div>
                </div>
                <div class=" my-4">
                    <div class="w-full border-t border-gray-300"></div>
                </div>

                <div>
                    <div class="flex justify-center">
                        <div class="mb-3 w-full">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Upload POD
                            </label>
                            <div class="flex">
                                <input @change="onClickFile2" id="file2" ref="myFiles2" accept="application/pdf" class="block w-4/5 cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" aria-describedby="user_avatar_help" type="file">
                                <a :href='order.PODUrl' target="_blank" v-if="order.PODUrl" type="button"  class="w-1/6 justify-center items-center flex border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                    <div class="text-center">
                                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd"></path></svg>
                                    </div>
                                    
                                </a>
                            </div>
                    
                        </div>
                    </div>
                </div>

                <div class="flex mb-6 mt-4 justify-center">
                    <div class="mb-3 w-full">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-md font-bold mb-2" >
                            Create order image gallery
                        </label>
                        <div class="flex -mx-3">
                            
                            <input @change="onClickFile4" id="file4" ref="myFiles4" accept="image/*" class="block w-4/5 mx-3 cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" type="file">
                            
                            <div class="w-1/5 px-3 mb-2 md:mb-0">
                                <button @click="addToGallery()" type="button"  class="px-3 w-full py-1 block justify-center items-center border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                                    <div class="text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 mx-auto w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-for="(image, index) in gallery" :key="index">
                    <div class="flex flex-wrap w-1/3">
                        <div class="w-full p-1 md:p-2">
                        <img @click="delImageModal(index)" alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                            :src="image.url"/>
                        </div>
                    </div>
                </div>

                <div class=" my-4">
                    <div class="w-full border-t border-gray-300"></div>
                </div>

                <div class="w-full mb-2 md:mb-0">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Remarks
                    </label>
                    <textarea rows="4" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="remarks" type="text" placeholder="Remarks"></textarea>
                </div>

                <div class="py-0 mb-2">
                    <div class="w-full border-t border-gray-300"></div>
                </div>

                <button type="button" @click="editOrder" class="w-full h-10 mt-6 mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-700 hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                    Edit order #{{order.orderId}}
                </button>
                <button type="button" @click="deleteOrder" class="w-full h-10 mt-6 mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                    Delete order #{{order.orderId}}
                </button>
            </form>
        </div>


        <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="delImgModal">
            <!--
                Background backdrop, show/hide based on modal state.

                Entering: "ease-out duration-300"
                From: "opacity-0"
                To: "opacity-100"
                Leaving: "ease-in duration-200"
                From: "opacity-100"
                To: "opacity-0"
            -->
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <!--
                    Modal panel, show/hide based on modal state.

                    Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                    Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>
                        </div>
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Delete image</h3>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">Are you sure you want to delete the image? The image will be deleted permanently. This action cannot be undone.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" @click="delImage()" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Delete image</button>
                        <button type="button" @click="delImgModal = false" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { collection, addDoc, getFirestore, deleteDoc, updateDoc, setDoc, doc, getDoc } from "firebase/firestore"
import emailjs from '@emailjs/browser';
import axios from 'axios'
export default {
    data(){
        return{
            iCntr:1,
            orders:[],
            order:[],
            user: null,
            expenses:[
                {
                    expenseHead: '',
                    value: '',
                    advance:''                    
                }
            ],
            id:'',
            orderId: '',
            customerAddress:'',
            customerName: '',
            customerEmail: '',
            customerContact: '',
            productOrdered: '',
            vendorName: '',
            sellingPrice:'',
            orderValue:[],
            file: null,
            file2: null,
            findex:'',
            invoiceUrl: null,
            PODUrl: null,
            orderDate:'',
            ordersSnap:[],
            status:'',
            etaDate:'',
            balancePending:'',
            gallery:[],
            remarks:'',
            oldRemarks:'',
            userRestricted: false,
            emailBy:'',
            delImgModal: false,
            imgCounter: '',
            restrictedEmails:['sales@trulysofas.com', 'narayansushma07@gmail.com' , 'rasaraf2206@gmail.com', 'oishikghosh@outlook.com', 'trulysofasops@gmail.com', 'aishwarya.subramanyam@voukino.com']
        }
    },
    async created(){
        try{
            //console.log(this.$store.state.order)
            this.order = this.$store.state.order
            if(!this.order){
                this.$router.push({ name: 'OrderTable' })
            }
            this.id = this.order.id
            this.orderId = this.order.orderId
            this.customerName = this.order.customerName
            this.customerEmail = this.order.customerEmail
            this.vendorName = this.order.vendorName
            this.expenses = this.order.expenses
            this.productOrdered = this.order.productOrdered
            this.customerContact = this.order.customerContact
            this.sellingPrice = this.order.sellingPrice
            this.customerAddress = this.order.customerAddress
            if(this.order.salesLead){
                this.salesLead = this.order.salesLead
            }
            if(this.order.invoiceUrl){
                this.invoiceUrl = this.order.invoiceUrl
            }
            if(this.order.remarks){
                this.remarks = this.order.remarks
                this.oldRemarks = this.order.remarks
            }
            if(this.order.gallery){
                this.gallery = this.order.gallery
            }
            if(this.order.etaDate){
                this.etaDate = this.order.etaDate
            }
            if(this.order.balancePending){
                this.balancePending = this.order.balancePending
            }
            if(this.order.PODUrl){
                this.PODUrl = this.order.PODUrl
            }
            if(this.order.orderDate){
                this.orderDate = this.order.orderDate
            }
            if(this.order.status){
                this.status = this.order.status
            }
            for (let index = 0; index < this.order.expenses.length; index++) {
                if(!this.order.expenses[index].advance){
                    this.order.expenses[index].advance = 0
                }
                
            }
            //console.log(this.order)
            var that = this
            const auth = getAuth();
            const db = getFirestore()
            await onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    const uid = user.uid;
                    this.emailBy = user.email
                    /* if(user.email == 'sales@trulysofas.com'|| 'narayansushma07@gmail.com' || 'RAsaraf2206@gmail.com'){
                        this.userRestricted = true
                        this.$store.commit('restrictUser', true)
                    } */
                    var doesInclude = this.restrictedEmails.includes(user.email.toLowerCase())
                    if(doesInclude) {
                        this.userRestricted = true
                        this.$store.commit('restrictUser', true)
                    }
                    console.log(this.userRestricted)
                    ///alert(uid)
                    // ...
                } else {
                    // User is signed out
                    // ...
                }
            });
            const docRef = doc(db, "ordersSnap", "snapshot");
            const docSnap = await getDoc(docRef);
            //this.orders = docSnap.data()
            var dataSnap = docSnap.data()
            var dataLength = Object.keys(dataSnap).length
            ///that.orders.push(dataSnap[0])
            
            for (let index = 0; index < dataLength; index++) {
                that.ordersSnap.push(dataSnap[index]);   
            }
            that.orders = dataSnap
            //console.log(that.ordersSnap)
            if (docSnap.exists()) {
                console.log("Document data:");
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }
        catch(e){
            console.log(e)
        }
    },
    methods:{
        async addToGallery(){
            try {
                if(!this.orderId){
                    alert('Enter order Id to create gallery')
                    return
                }
                const storage = getStorage();
                const ext = this.file4.name.slice(this.file4.name.lastIndexOf('.'))
                var imgName = this.orderId + '_' + this.iCntr + ext
                const storageRef = ref(storage, 'products/'+ imgName)
                await uploadBytes(storageRef, this.file4).then((snapshot) => {
                    console.log('Uploaded image to product gallery');
                    //console.log(snapshot)
                })
                await getDownloadURL(storageRef).then((downloadURL) => {
                    var newImage = {
                        name: imgName,
                        url: downloadURL
                    }
                    this.gallery.push(newImage)
                    this.iCntr = this.iCntr + 1
                    this.$refs.myFiles.value = null
                    console.log('File uploaded');
                });
            } catch (error) {
                
            }
            
        },
        onClickFile4(){
            this.file4 = this.$refs.myFiles4.files[0]
        },
        async delImage(){
            try {
                const storage = getStorage()
                const nameref = 'products/' + this.gallery[this.imgCounter].name
                const desertRef = ref(storage, nameref);
                deleteObject(desertRef).then(() => {
                    console.log('image deleted')
                }).catch((error) => {
                    console.log(error)
                });
                this.gallery.splice(this.imgCounter, 1)
                this.delImgModal = false
                this.imgCounter = ''
                
            } catch (error) {
                
            }
        },
        onClickFile(){
            this.file = this.$refs.myFiles.files[0]
            //console.log(this.file.name)
            /* const storage = getStorage();
            const storageRef = ref(storage, 'orders/'+ this.orderId + '_invoice.pdf');

            // 'file' comes from the Blob or File API
            uploadBytes(storageRef, this.file).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            console.log(snapshot)
            }) */
            
        },
        delImageModal(index){
            this.imgCounter = index
            this.delImgModal = true
        },
        onClickFile2(){
            this.file2 = this.$refs.myFiles2.files[0]
            //console.log(this.file2.name)
            /* const storage = getStorage();
            const storageRef = ref(storage, 'orders/'+ this.orderId + '_POD.pdf');

            // 'file' comes from the Blob or File API
            uploadBytes(storageRef, this.file2).then((snapshot) => {
                console.log('Uploaded a blob or file!');
                console.log(snapshot)
            })
            getDownloadURL(storageRef).then((downloadURL) => {
                console.log('File available at', downloadURL);
            }); */
            
        },
        addExpense(){
            this.expenses.push({
                expenseHead: '',
                value: '',
                advance:'',
                balance:''
            })
            //console.log(this.expenses)
        },
        removeExpense(index){
            this.expenses.splice(index, 1)
        },
        async deleteOrder(){
            try{
                if(this.userRestricted){
                    const res = await axios.post( process.env.VUE_APP_SLACK_DELETEORDER, {
                        orderId: this.orderId,
                        email: this.emailBy,
                    })
                    console.log(res)
                    alert('you are not authorised to delete orders')
                    return
                }
                var that = this
                const db = getFirestore()
                await deleteDoc(doc(db, "orders", this.order.id))
                ///var dataLength = Object.keys(that.orders).length
           
                for (let index = 0; index < that.ordersSnap.length; index++) {
                    if(that.ordersSnap[index].id == that.id){
                        that.ordersSnap.splice(index, 1)
                        ///console.log(that.ordersSnap)
                    }
                }
                const snapshot = Object.assign({}, that.ordersSnap)
                await setDoc(doc(db, "ordersSnap", "snapshot"), snapshot)
                this.$router.push({ name: 'OrderTable' })


            }catch(e){
                console.log(e)
            }
        },

        async editOrder(){
            try {
                
                var that = this
                var today = new Date().toLocaleDateString()
                const storage = getStorage();
                if(this.file2){
                    const storageRef = ref(storage, 'orders/'+ this.orderId + '_POD.pdf');

                    // 'file' comes from the Blob or File API
                    await uploadBytes(storageRef, this.file2).then((snapshot) => {
                        console.log('Uploaded a blob or file!');
                        //console.log(snapshot)
                    })
                    await getDownloadURL(storageRef).then((downloadURL) => {
                        that.PODUrl = downloadURL
                        //console.log('File available at', downloadURL);
                    });
                }
                if(this.file){
                    const storageRef = ref(storage, 'orders/'+ this.orderId + '_invoice.pdf');

                    // 'file' comes from the Blob or File API
                    await uploadBytes(storageRef, this.file).then((snapshot) => {
                        console.log('Uploaded a blob or file!');
                        //console.log(snapshot)
                    })
                    await getDownloadURL(storageRef).then((downloadURL) => {
                        that.invoiceUrl = downloadURL
                        //console.log('File available at', downloadURL);
                    });
                }
                if(this.remarks !== this.oldRemarks){
                    emailjs.send('service_i0krftm', 'template_ukebw9r', { order_number:this.orderId, order_email: this.emailBy, status: this.status, remarks: this.remarks }, '2VKjYxfSnWiMedzk5')
                    .then((result) => {
                        console.log('SUCCESS!', result.text);
                    }, (error) => {
                        console.log('FAILED...', error.text);
                    });
                }

                var dataLength = Object.keys(that.orders).length
                for (let index = 0; index < dataLength; index++) {
                    if(that.orders[index].id == that.id){
                        //console.log(that.orders[index].id)
                        that.findex = that.orders[index].id
                        that.orders[index].orderId = that.orderId
                        that.orders[index].customerName = that.customerName
                        that.orders[index].customerEmail = that.customerEmail
                        that.orders[index].customerContact = that.customerContact
                        that.orders[index].vendorName = that.vendorName
                        that.orders[index].sellingPrice = that.sellingPrice
                        that.orders[index].expenses = that.expenses
                        that.orders[index].customerAddress = that.customerAddress
                        that.orders[index].invoiceUrl = that.invoiceUrl
                        that.orders[index].PODUrl = that.PODUrl
                        that.orders[index].productOrdered = that.productOrdered
                        that.orders[index].editedOn = today,
                        that.orders[index].orderDate = that.orderDate,
                        that.orders[index].status = that.status,
                        that.orders[index].balancePending = that.balancePending,
                        that.orders[index].etaDate = that.etaDate
                        that.orders[index].gallery = that.gallery
                        that.orders[index].remarks = that.remarks
                        that.orders[index].salesLead = that.salesLead
                        
                    } 
                }
                const db = getFirestore()
                await setDoc(doc(db, "ordersSnap", "snapshot"), that.orders)
                const docRef = doc(db, "orders", that.findex)
                await updateDoc(docRef, {
                    orderId: that.orderId,
                    customerName: that.customerName,
                    customerEmail: that.customerEmail,
                    customerContact: that.customerContact,
                    productOrdered: that.productOrdered,
                    vendorName: that.vendorName,
                    sellingPrice:that.sellingPrice,
                    expenses: that.expenses,
                    invoiceUrl: that.invoiceUrl,
                    PODUrl: that.PODUrl,
                    customerAddress: that.customerAddress,
                    orderDate: that.orderDate,
                    status: that.status,
                    editedOn: today,
                    balancePending: this.balancePending,
                    etaDate: this.etaDate,
                    gallery: this.gallery,
                    remarks: this.remarks,
                    salesLead: that.salesLead
                });

                this.$router.push({ name: 'OrderTable' })
                console.log("Document written ");
            } catch (e) {
                //console.error("Error adding document: ", e);
            }

        }
    }
}
</script>