<template>
    <div class="font-serif">
        <h1 class="text-center text-3xl">Create Quote</h1>
        <div class="flex  justify-center">
            <form class="w-full max-w-lg mx-2 mt-6" @submit.prevent>
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Customer Name
                        </label>
                        <input v-model="customerName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Customer name?">
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Customer email
                        </label>
                        <input v-model="customerEmail" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Customer email?">
                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Customer contact number?
                        </label>
                        <input v-model="customerContact" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Customer contact number?">
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                            Quotation date?
                        </label>
                        <input v-model="quoteDate" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="date of quotation?">
                    </div>
                </div>
                <div class="w-full mb-6">
                    <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                        Customer address
                    </label>
                    <textarea v-model="customerAddress" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"  type="text" placeholder="Enter address"></textarea>
                </div>
                <div>
                    <h1 class="text-xl font-bold text-center py-4">Items:</h1>
                </div>
                <div v-for="(product, index) in products" :key="index" class="flex flex-wrap -mx-3">
                    <div class="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                        <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Product name
                        </label>
                        <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="product.productName" type="text" placeholder="Product name">
                    </div>
                    <div class="w-full flex flex-wrap md:w-1/2 mb-2 md:mb-0">

                        <div class="w-4/5 px-3 mb-0 md:mb-0">
                            <div class="">
                                <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Selling price
                                </label>
                                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="product.sellingPrice"  type="number" placeholder="Price">
                            </div>
                        </div>

                        <button type="button" @click="removeItem(index)" class="w-1/6 h-12 mt-6 mr-2 py-2  border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                            DEL
                        </button>

                    </div>
                    <div class="w-full px-3 flex flex-wrap md:w-full mb-2 md:mb-0">
                        <div class="w-4/5 mb-0 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Details
                            </label>
                            <textarea class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="product.details"  type="number" placeholder="Details"></textarea>
                        </div>
                        <div class="w-1/5 px-3 mb-0 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Qty
                            </label>
                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="product.quantity"  type="number" placeholder="Qty">

                        </div>

                    </div>
                    <div class="w-full flex flex-wrap md:w-full mb-2 md:mb-0">
                        <div class="w-full px-3 mb-0 md:mb-0">
                            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                Image file
                            </label>
                            <input class="appearance-none block text-xs w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" @change="onClickFile2(index)" id="file2" ref="myFiles" accept="image/*"  type="file" placeholder="Value">
                        </div>
                    </div>
                    <img v-if="product.image" :src="product.image.url" class="h-36 px-3" alt="">

                </div>
                
                <button type="button" @click="addItem" class="w-full h-10 mt-6 mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                    Add item
                </button>
            </form>
            
        </div>
        <div class="bg-black flex justify-center">
            <div>
                <vue3-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="true"
                    :preview-modal="true"
                    :paginate-elements-by-height="1400"
                    filename= "TS_quotation"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    pdf-orientation="landscape"
                    pdf-content-width="100%"

                    @progress="onProgress($event)"
                    @hasStartedGeneration="hasStartedGeneration()"
                    @hasGenerated="hasGenerated($event)"
                    @beforeDownload="beforeDownload($event)"
                    ref="html2Pdf"
                >
                    <template  v-slot:pdf-content>
                        <div class=" mx-auto">
                            <div class="flex font-serif">
                                <div class="w-1/4">
                                    <img class=" h-32 w-auto m-4 bg-black justify-start" src="@/assets/ts_logo.jpeg" alt="Workflow">
                                </div>
                                <div class="w-3/4">
                                    <h2 class="text-center text-2xl py-2 mt-4 text-gray-700">Truly Sofas estimate for {{ customerName }} </h2>
                                </div>
                            </div>
                            <div class="py-4">
                                <h1 class="text-left ml-8">To, <br> {{ customerName }} <br> {{ customerAddress }} <br> Date: {{ quoteDate }}</h1> 
                            </div>

                            <div class="mx-4  lg:block">
                                <table class="my-12 table-auto border-collapse shadow-lg min-w-full mx-auto px-4">
                                    <thead class="text-left uppercase bg-gray-200">
                                        <tr>
                                            <th class="px-8 py-4 w-80 max-h-80">Image</th>
                                            <th class="px-8 py-4">Product</th>
                                            <th class="px-8 py-4">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody class="">
                                        <tr v-for="(product, index) in products" :key="index">
                                            <td class="border px-8 py-4">
                                                <div v-if="product.image">
                                                    <img :src="product.image.url" class=" block w-full h-auto mx-auto" alt="">
                                                </div>
                                                
                                            </td>
                                            <td class="border px-8 py-4">
                                                <h1 class="text-xl uppercase">{{product.productName}}</h1>
                                               <!--  <button @click="gotoSofa(cartItem)" class="text-xs"><span class="uppercase" v-text="`<  back to sofa`"></span></button> -->
                                                <p class="pt-2">Details: {{product.details}}</p>
                                                <p>Price: {{product.sellingPrice}}</p>
                                                <p>Quantity: {{product.quantity}}</p>
                                            </td>
<!--                                             <td class="border px-8 py-4">Rs.{{product.sellingPrice}}</td>
                                            <td class="border px-8 py-4">
                                                <input v-model="product.quantity" disabled class="appearance-none block w-20 mx-auto bg-gray-200 text-black border border-footer-black pb-2 px-4 leading-tight focus:border-none"  type="number" />
                                            </td> -->
                                            <td class="border px-8 py-4">Rs.{{product.sellingPrice * product.quantity}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            
                            <div v-show="products.length" class="mx-6">
                                <h1 class="uppercase my-4 text-2xl titleText text-center">Order summary</h1>
                                <table class="mx-auto table-auto border-collapse">
                                    <tbody>
                                        <tr>
                                            <th class="md:px-20 px-4 pt-4 uppercase text-xl font-light">Total:</th>
                                            <td class="md:px-20 px-4 pt-4 font-bold text-xl">Rs.{{quoteTotal}}</td>
                                        </tr>
                                        <tr v-if="discount">
                                            <th class="md:px-20 px-4 py-1 uppercase text-xl font-light">Discount:</th>
                                            <td class="md:px-20 px-4 py-1 font-bold text-xl">Rs.{{discount}}</td>
                                        </tr>
                                        <tr v-if="discount">
                                            <th class="md:px-20 px-4 py-1 uppercase text-xl font-light">Final price:</th>
                                            <td class="md:px-20 px-4 py-1 font-bold text-xl">Rs.{{quoteTotal - discount}}</td>
                                        </tr>
                                    </tbody>
                                    
                                </table>
                            </div>

                            <div class="mx-6 py-4">
                                <h1 class="text-xl">Please note:</h1>
                                <p class="text-sm">70% advance to be paid on placing the order , balance 30% to be paid before dispatch. Auto cad's and 3d's will be provided once the order is placed if needed and requested .</p>
                            </div>
                            <div class="mx-6 pb-4">
                                <p class="text-sm">This estimate is generated by Truly Sofas. All images and details mentioned above are property of Truly Sofas. None of the information shared can disclosed to other 3rd party person/s without consent from Truly Sofas. For Any further information write to us at aishwarya@trulysofas.com</p>
                                <div class="flex justify-center py-4">
                                    <h1 class="text-center inline-block text-sm"> <span><img class="w-6 h-6 mt-2 inline-block" src="@/assets/wwwlogo.png" alt=""></span> https://trulysofas.com  &nbsp &nbsp &nbsp &nbsp  <span><img class="w-6 h-6 mt-2 inline-block" src="@/assets/instalogo.png" alt=""></span> @trulysfas.shop</h1>
                                </div>
                               
                            </div>
                        </div> 
                    </template>
                </vue3-html2pdf>
            </div>
        </div>
        <div class="w-full px-3 max-w-lg mx-auto pt-6 mb-2 md:mb-0">
            <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Total discount
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" v-model="discount" type="text" placeholder="Total discount applied">
        </div>
        <div>
            <button type="button" @click="generateReport" class="w-full h-10 mt-6 mb-4 max-w-lg py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-800 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> 
                Create pdf
            </button>
        </div>
        
    </div>
</template>

<script>
import Vue3Html2pdf from 'vue3-html2pdf'
import html2canvaspro from 'html2canvas-pro'
import axios from "axios"
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"
//import VueHtml2pdf from 'vue-html2pdf'

export default {
    components: {
        Vue3Html2pdf,
        html2canvaspro
        //VueHtml2pdf
    },
    data(){
        return{
            filename:'',
            customerName:'',
            customerEmail:'',
            customerContact:'',
            customerAddress:'',
            quoteDate:'',
            products:[
                {
                    productName: '',
                    details: '', 
                    sellingPrice:'',
                    image:[],
                    quantity:''                  
                }
            ],
            imageUrl:'',
            file2:'',
            quotations:null,
            images:[
                {
                    image:''
                }
            ],
            discount:'',
        }
    },
    async created(){
        try {
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth()+1;//January is 0!`

            var yyyy = today.getFullYear();
            if(dd<10){dd='0'+dd}
            if(mm<10){mm='0'+mm}
            var today = mm+'/'+dd+'/'+yyyy;
            console.log(today)

            /* const res = await axios.get( process.env.VUE_APP_GET_QUOTATIONS );
            this.quotations = res.data
            console.log(this.quotations) */
        } catch (error) {
            
        }
    },
    computed: {
        quoteTotal() {
            var cartTotalNum = 0
            for (let index = 0; index < this.products.length; index++) {
                var itemTotal = Number(this.products[index].sellingPrice) * Number(this.products[index].quantity)
                cartTotalNum = cartTotalNum + itemTotal
            }
            return cartTotalNum
        },
        
    },
    methods:{
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            var opt = {
                html2canvaspro: { scale: 1, useCORS: true },
                jsPDF: { orientation: 'landscape' },
                filename: 'TS_Quotation_' + this.customerName,
            };
            await html2pdf().set(opt).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    //pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
        async generateReport () {
            try {
                const res = await axios.post( process.env.VUE_APP_ADD_QUOTATION, {
                    products: this.products,
                    customerName: this.customerName,
                    customerEmail: this.customerEmail,
                    customerContact: this.customerContact,
                    customerAddress: this.customerName,
                    productColors: this.productColors,
                    quoteDate: this.quoteDate,
                    discount: this.discount
                })
                console.log(res)
                await this.$refs.html2Pdf.generatePdf()
                var element = document.getElementById('html2pdf');
                html2pdf(element);
            } catch (error) {
                
            }
            
            /* let options = {
                margin: 0,

                filename: `hehehe.pdf`,

                image: {
                    type: 'jpeg', 
                    quality: 0.98
                },

                enableLinks: false,

                html2canvas: {
                    scale: 2,
                    useCORS: true,
                    letterRendering: true
                },

                jsPDF: {
                    unit: 'pt',
                    format: 'a4',
                    orientation: 'portrait'
                } 
            }

            await html2pdf().set(options).from(pdf-content).save() */
        },
        removeItem(index){
            this.products.splice(index, 1)
        },
        addItem(){
            this.products.push({
                productName: '',
                details: '', 
                sellingPrice:'',
                image:[] ,
                quantity:''
            })
            console.log(this.products)
            //console.log(this.expenses)
        },
        async onClickFile2(index){
            //console.log(this.$refs.myFiles[index].files[0])
            this.file2 = this.$refs.myFiles[index].files[0]
            console.log(this.file2)
            var imgName = this.file2.name
            const storage = getStorage();
            const storageRef = ref(storage, 'quotes/'+ imgName)

            await uploadBytes(storageRef, this.file2).then((snapshot) => {
                console.log('Uploaded image to product gallery');
                //console.log(snapshot)
            })
            await getDownloadURL(storageRef).then((downloadURL) => {
                var newImage = {
                    name: imgName,
                    url: downloadURL
                }
                console.log(newImage)
                this.products[index].image = newImage
                //this.iCntr = this.iCntr + 1
                //this.$refs.myFiles.value = null
                console.log('File uploaded');
            });
            
            /* const fr = new FileReader();
                fr.readAsDataURL(this.file2)
                fr.addEventListener("load", () => {
                //this.imageUrl = fr.result
                this.images[index].image = fr.result
            }); */
            const ext = this.file2.name.slice(this.file2.name.lastIndexOf('.'))
            
        },
    }
}
</script>

<style scoped>
tr, td, h1 {
    page-break-inside: avoid !important;
}
</style>