<template>
    <div>
        <nav class="relative flex flex-wrap items-center justify-between px-2 py-3 bg-black mb-3">
            <div class="container px-2 mx-auto flex flex-wrap items-center justify-between">
            <div class="w-full relative flex justify-between md:w-auto  px-2 md:static md:block md:justify-start">
                <img class=" h-16 w-auto inline-block mr-4" src="@/assets/tslogo.png" alt="Workflow">
                <a class="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white" href="">
                Truly sofas
                </a>
                <button class="text-white cursor-pointer text-xl leading-none px-1 py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none" type="button" v-on:click="toggleNavbar()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>
            <div v-bind:class="{'hidden': !showMenu, 'flex': showMenu}" class="md:flex md:flex-grow items-center">
                <ul class="flex flex-col md:flex-row list-none ml-auto">
                <li class="nav-item">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="/home">
                    <span class="ml-2">Home</span>
                    </a>
                </li>
                <li class="nav-item" v-if="userRestricted == false">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="/addthryvproduct">
                    <span class="ml-2">Add Thryv</span>
                    </a>
                </li>
                <li class="nav-item" v-if="userRestricted == false">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="/addproduct">
                    <span class="ml-2">Add Product</span>
                    </a>
                </li>
                <li class="nav-item" v-if="userRestricted == false">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="/viewproducts">
                    <span class="ml-2">View Products</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="/addorder">
                    <span class="ml-2">Add Order</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="/list">
                    <span class="ml-2">View orders</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="/createquote">
                    <span class="ml-2">Create quote</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="/viewquotations">
                    <span class="ml-2">View Quotations</span>
                    </a>
                </li>
                <li class="nav-item" v-if="user">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" @click="signout">
                    <span class="ml-2">Signout</span>
                    </a>
                </li>
                <li class="nav-item" v-if="!user">
                    <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="/">
                    <span class="ml-2">Login</span>
                    </a>
                </li>
                </ul>
            </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth"
export default {
  name: "blueGray-navbar",
  data() {
    return {
      showMenu: false,
      user:null,
      userRestricted: false,
      userEmail:''
    }
  },
  methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    },
    signout(){
        const auth = getAuth()
        signOut(auth).then(() => {
            this.$router.push({name: 'Login'})
        }).catch((error) => {
            alert('Unable to signout')
        })
    }
  },
  async created(){
        if(this.$store.state.userRestricted == false){
            this.userRestricted = false
        }
        const auth = await getAuth()
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                this.user = user.uid;
                if(user.email == 'sales@trulysofas.com'){
                    this.userRestricted = true
                    this.$store.commit('restrictUser', true)
                }
                //console.log(user.email)
                
                ///alert(uid)
                // ...
            } else {
                // User is signed out
                // ...
            }
            //console.log(this.userEmail)
            //console.log(this.userRestricted)
        });

  }
}
</script>